import { sanitize } from 'dompurify';

import { useHandleIframeModal } from '@jane/shared-ecomm/hooks';
import { Button, Flex, Modal, Typography } from '@jane/shared/reefer';

import {
  StyledDiv,
  StyledLink,
  StyledModalContent,
} from './textCommsBanner.styles';

const cleanBannerText = ({
  message,
  title,
}: {
  message?: string;
  title: string;
}) => {
  if (!message) return title;
  // Create a temporary element to manipulate the HTML
  const tempElement = document.createElement('div');
  tempElement.innerHTML = message;

  let cleanedHTML = tempElement.textContent || tempElement.innerText || '';

  // Replace multiple spaces with a single space
  cleanedHTML = cleanedHTML.replace(/\s+/g, ' ').trim();

  return `${title}: ${cleanedHTML}`;
};

export interface TextCommsBannerProps {
  handleShowModal: (showModal: boolean) => void;
  isMobile?: boolean;
  message?: string | null;
  showModal: boolean;
  title?: string | null;
}

export const TextCommsBanner = ({
  isMobile = false,
  handleShowModal,
  showModal,
  title,
  message,
}: TextCommsBannerProps) => {
  useHandleIframeModal(showModal);

  const bannerText = cleanBannerText({
    title: title || '',
    message: message || undefined,
  });

  return (
    <>
      <Flex
        {...(isMobile && {
          ariaLabel: 'view-more',
          onClick: () => handleShowModal(true),
        })}
        justifyContent="space-between"
      >
        <StyledDiv
          dangerouslySetInnerHTML={{
            __html: sanitize(bannerText),
          }}
        />

        {!isMobile && (
          <StyledLink
            ml={16}
            ariaLabel="view-more"
            branded
            onClick={() => handleShowModal(true)}
          >
            View more
          </StyledLink>
        )}
      </Flex>
      <Modal
        onRequestClose={() => handleShowModal(false)}
        open={showModal}
        variant="dialogue"
        shouldCloseOnEsc
        overlayClose
      >
        <StyledModalContent>
          {title && (
            <Typography as="h1" branded variant="header-bold">
              {title}
            </Typography>
          )}

          {message && (
            <Typography mt={8} as="div" branded variant="body">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(message),
                }}
              />
            </Typography>
          )}

          <Button
            full
            mt={24}
            onClick={() => handleShowModal(false)}
            label="Close"
          />
        </StyledModalContent>
      </Modal>
    </>
  );
};
