import { ThemeProvider } from '@emotion/react';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { MenuHeaderEmbed } from '@jane/shared-ecomm/components';
import { useCurrentStoreId } from '@jane/shared-ecomm/hooks';
import {
  AuthenticationProvider,
  UserPreferencesProvider,
} from '@jane/shared-ecomm/providers';
import { trackCartClick } from '@jane/shared-ecomm/tracking';
import {
  EmbeddedHeader,
  LoadingWrapper,
  MfaModal,
} from '@jane/shared/components';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { Store } from '@jane/shared/models';
import { MfaProvider, useEcommApp } from '@jane/shared/providers';
import {
  Box,
  Flex,
  ReeferThemeProvider,
  ToastProvider,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import { StoreScripts } from '@jane/shared/scripts';
import {
  PaginationCountProvider,
  calcNumberOfCartItems,
  getEcommPaths,
  parseSearch,
} from '@jane/shared/util';

import {
  setAppMode,
  useCustomerDispatch,
  useCustomerSelector,
} from '../../customer';
import {
  openCart,
  updateQueryPromoCode,
  updateTags,
} from '../../customer/redux/cart';
import { whoami } from '../../customer/redux/customer';
import { setPartner, verifyStore } from '../../customer/redux/embeddedApp';
import type { NoStore } from '../../customer/redux/store';
import { isNoStore } from '../../customer/redux/store';
import {
  getReservationsByStore,
  setPreviousPath,
} from '../../customer/redux/users';
import {
  inProgressStatuses,
  reservationsInProgressSelector,
} from '../../customer/selectors';
import { useAuth } from '../../hooks/useAuth/useAuth';
import { useEmbeddedOptionsListener } from '../../hooks/useEmbeddedOptionsListener';
import { useForceClientRefresh } from '../../hooks/useForceClientRefresh';
import { useRecordPageView } from '../../hooks/useRecordPageView';
import { getStoreTheme } from '../../lib/getTheme';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import { PendingCartDrawer } from '../../shared-components';
import { DEFAULT_THEME } from '../../theming';
import { IdentifyCustomer } from '../../tracking';
import { SetJaneDeviceId } from '../SetJaneDeviceId';
import { ApplicationGates } from '../applicationGates';
import CheckAuthTimer from '../checkAuthTimer';
import ServerNotifications from '../notifications/server';
import { ScrollPositionProvider } from '../storeDetail/scrollPositionProvider';

const buildTheme = (store: Store | NoStore) => {
  if (isNoStore(store) || !store.white_label_enabled) {
    return DEFAULT_THEME;
  }
  return getStoreTheme(store);
};

export const FramelessEmbedAppContainer = () => {
  const myHigh = useFlag(FLAGS.myHighMenu);
  useForceClientRefresh();
  useRecordPageView();
  const dispatch = useCustomerDispatch();
  const location = useLocation();
  const authentication = useAuth();
  const isMobile = useMobileMediaQuery({});
  const { theme } = useEmbeddedOptionsListener();
  const { isPartnerHosted, partnerHostedPath, isBoostForAll } =
    useRuntimeConfig();
  const initialStoreId = useCurrentStoreId();
  const { appMode } = useEcommApp();

  const { authenticated, sessionChecked } = useCustomerSelector(
    get('customer')
  );
  const { hasLoaded: hasLoadedCart } = useCustomerSelector(get('cart'));
  const {
    hasLoaded: hasLoadedStore,
    isLoadingStore,
    store,
  } = useCustomerSelector(get('store'));
  const { disableAuthFeatures, hasVerified, partnerId } = useCustomerSelector(
    get('embeddedApp')
  );
  const cart = useCustomerSelector((state) => state.cart.cart);
  const { customerId } = useCustomerSelector((state) => ({
    customerId: state.customer.id,
  }));
  const inProgressReservations = useCustomerSelector((state) =>
    reservationsInProgressSelector(state.users)
  );
  const hidePrices = useCustomerSelector(({ store: { store } }) =>
    isNoStore(store) ? true : store.hide_prices
  );

  const isLoading =
    !sessionChecked ||
    !hasLoadedCart ||
    !hasLoadedStore ||
    isLoadingStore ||
    !hasVerified;

  const previousPathRef = useRef(location.pathname);

  const storePathProps =
    'id' in store
      ? { id: store?.id, name: store?.name, slug: store?.url_slug }
      : { id: '', name: '', slug: '' };

  const nonCompletedReservations = inProgressReservations.filter(
    (reservation) =>
      inProgressStatuses.includes(reservation.status) &&
      (!partnerId || (partnerId && reservation.store.id === partnerId))
  );

  const ecommPaths = getEcommPaths({
    appMode: 'framelessEmbed',
    isBoostForAll,
    isPartnerHosted,
    partnerHostedPath,
    storePathProps,
  });

  useEffect(() => {
    dispatch(setPreviousPath(previousPathRef.current));
    previousPathRef.current = location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setAppMode('framelessEmbed'));
  }, []);

  useEffect(() => {
    const query = parseSearch(window.location.search);
    dispatch(updateTags(query));
    dispatch(updateQueryPromoCode(query['promo_code']));

    dispatch(setPartner({ id: initialStoreId, name: '' }));
    dispatch(
      verifyStore({
        storeId: initialStoreId,
        options: {
          forceEmbed: !!query['embed'],
          isPartnerHosted,
          framelessEmbed: true,
        },
      })
    );
    const maybeRef = query['ref'];
    const ref = typeof maybeRef === 'string' ? maybeRef : undefined;
    dispatch(whoami({ ref }));
    const body = document.querySelector('body');
    if (body) {
      body.classList.add('embedded'); // set fixed modal height for embedded/whiteLabel. see layout.css
    }
  }, []);

  useEffect(() => {
    if (authenticated && partnerId) {
      dispatch(getReservationsByStore(partnerId));
    }
  }, [authenticated, partnerId]);

  const onCartClick = () => {
    dispatch(openCart());
    trackCartClick();
  };

  const shadowHost = document.getElementById('shadow-host')?.shadowRoot;
  const drawerContainer = shadowHost?.getElementById(
    'shadow-app-sibling'
  ) as HTMLElement;

  return (
    <LoadingWrapper isLoading={isLoading}>
      <StoreScripts storeId={partnerId} />
      <CheckAuthTimer />
      <SetJaneDeviceId />

      <IdentifyCustomer
        app="framelessEmbed"
        appStoreId={initialStoreId}
        queryParams={parseSearch(window.location.search)}
      />
      <PaginationCountProvider>
        <ScrollPositionProvider>
          <ReeferThemeProvider
            renderProvider={(theme, children) => (
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
            )}
            theme={theme || buildTheme(store)}
          >
            <ToastProvider position={isMobile ? 'top-middle' : 'top-right'}>
              <MfaProvider>
                <UserPreferencesProvider>
                  <AuthenticationProvider value={{ ...authentication }}>
                    <PendingCartDrawer container={drawerContainer} />
                    <MfaModal />
                    {/*TODO: can ReeferThemeProvider get moved to the top level?*/}
                    <ApplicationGates />

                    <ServerNotifications />
                    <Flex pb={24} flexDirection="column" grow>
                      {myHigh && (
                        <MenuHeaderEmbed
                          py={24}
                          disableAuthFeatures={disableAuthFeatures}
                          inProgressReservations={
                            nonCompletedReservations.length
                          }
                          onCartClick={onCartClick}
                          paths={ecommPaths}
                          productsInCart={calcNumberOfCartItems(cart)}
                          store={store as Store}
                        />
                      )}

                      {!myHigh && (
                        <EmbeddedHeader
                          appMode={appMode}
                          cart={cart}
                          customerId={customerId}
                          disableAuthFeatures={disableAuthFeatures}
                          hasUser={!!customerId}
                          hidePrices={hidePrices}
                          inProgressReservations={inProgressReservations}
                          onCartClick={onCartClick}
                          partnerId={partnerId}
                          routeAppMode={paths.getAppMode()}
                          routePartnerHostedConfig={paths.getPartnerHostedConfig()}
                          store={
                            isNoStore(store) ? undefined : (store as Store)
                          }
                        />
                      )}
                      <Box>
                        <LoadingWrapper isLoading={isLoading}>
                          <Outlet />
                        </LoadingWrapper>
                      </Box>
                    </Flex>
                  </AuthenticationProvider>
                </UserPreferencesProvider>
              </MfaProvider>
            </ToastProvider>
          </ReeferThemeProvider>
        </ScrollPositionProvider>
      </PaginationCountProvider>
    </LoadingWrapper>
  );
};
