import { api, generateTrackingUrls, generateUrl } from '../api';
import type { AdProduct } from '../types/adProduct';
import type { JaneDMIdentifiers } from '../types/config';
import type { Brand } from '../types/flight';
import { zoneStoreMenu } from '../types/zones';
import { typedJSONParse } from '../utils/typedJsonParse';
import type {
  CreateTopOfMenuRowProps,
  FetchTopOfMenuRowApiResponse,
  TopOfMenuRowClickPayload,
  TopOfMenuRowProductClickPayload,
  TopOfMenuRowProductProps,
  TopOfMenuRowProps,
  TopOfMenuRowRenderPayload,
} from './topOfMenuRow.types';

/**
 * Represents a product, called menu product in the Jane ecosystem, for a
 * top of menu row placement.
 */
export class TopOfMenuRowProduct {
  protected clickPayload: TopOfMenuRowProductClickPayload;
  protected clickEndpoint: string;
  /**
   * The primary identifier for this product.
   */
  public productId: number;
  /**
   * The attributes of this product.
   * The shape and data of this field will change depending on the requested
   * attributes.
   * @example
   * ```jsx
   * { product_id: '1234' }
   * ```
   */
  public attributes: Record<string, any>;

  /**
   * Builds a new TopOfMenuRowProduct instance based on the provided properties.
   * This method should be used in conjunction with `serialize()` to recreate the instance.
   */
  constructor(props: TopOfMenuRowProductProps) {
    this.productId = props.productId;
    this.clickEndpoint = props.clickEndpoint;
    this.clickPayload = props.clickPayload;
    this.attributes = props.attributes;
  }

  /**
   * This method allows you to replace/set custom attributes of the product,
   * so that only this product instance needs to be provided to the
   * components, without having to pair it with another object.
   * @example
   * ```jsx
   * product.setAttributes({ taste: 'good' });
   * ```
   */
  public setAttributes(attributes: Record<string, any>) {
    this.attributes = attributes;
  }

  /**
   * Records attribution data for this product. This method
   * should be called when the product is clicked by the user.
   */
  public click(): Promise<void> {
    return api.post(this.clickEndpoint, this.clickPayload);
  }

  /**
   * Converts the object in a JSON-serializable data structure that should be
   * used **unmodified** in the constructor to create a new instance of this class.
   * This method should be used in conjunction with `new TopOfMenuRowProduct()` to recreate the instance.
   *
   * This function is especially helpful in a server-side environment to
   * keep track of the same instance across client side and server side
   * rendered components.
   * @example
   * ```jsx
   * let serializedObj = obj.serialize();
   * let deserializedObj = new TopOfMenuRowProduct(serializedObj);
   * ```
   */
  public serialize(): TopOfMenuRowProductProps {
    return {
      attributes: this.attributes,
      clickEndpoint: this.clickEndpoint,
      clickPayload: this.clickPayload,
      productId: this.productId,
    };
  }

  /**
   * Converts the object to a JSON string, using `serialize()` behind the
   * scenes. The returned value should be used **unmodified** in the
   * `TopOfMenuRowProduct.fromJSON()` static method.
   * This method should be used in conjunction with `TopOfMenuRowProduct.fromJSON()` to recreate the instance.
   */
  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  /**
   * Converts the previously-serialized object back to an instance of this class.
   * This method should be used in conjunction with `toJSON()` to recreate the instance.
   */
  public static fromJSON(serialized: string): TopOfMenuRowProduct {
    return new TopOfMenuRowProduct(
      typedJSONParse<TopOfMenuRowProductProps>(serialized)
    );
  }
}

/**
 * Represents a top of menu row placement, that is, a row of products to be
 * displayed at the top of the page displaying all store's products.
 *
 * In a server side context, this instance can be recreated over to the
 * client by using the **unmodified** properties provided by the `serialize()`
 * method.
 */
export class TopOfMenuRow {
  /**
   * Placement title.
   */
  public title: string | undefined;
  /**
   * A mix of organic and sponsored products for this placement.
   * @example
   * ```jsx
   * placement.products.map((product) => {
   *   return (<ProductCard
   *     product={product}
   *     onClick={() => product.click()}
   *     key={product.productId}
   *   />);
   * });
   * ```
   */
  public products: TopOfMenuRowProduct[];
  /**
   * List of creative ids for this placement.
   */
  public creativeIds: number[];

  protected filterBrands: Brand[] | null | undefined;
  protected flightBrand: Brand | undefined;

  private clickEndpoint: string;
  private clickPayload: TopOfMenuRowClickPayload;

  private renderEndpoint: string;
  private renderPayload: TopOfMenuRowRenderPayload;
  private hasBeenRendered: boolean;

  /**
   * Builds a new TopOfMenuRow instance based on the provided properties.
   * This method should be used in conjunction with `serialize()` to recreate the instance.
   */
  constructor(props: TopOfMenuRowProps) {
    this.products = props.products.map(
      (product) => new TopOfMenuRowProduct(product)
    );
    this.clickPayload = props.clickPayload;
    this.renderPayload = props.renderPayload;
    this.title = props.title;
    this.clickEndpoint = props.clickEndpoint;
    this.renderEndpoint = props.renderEndpoint;
    this.creativeIds = props.creativeIds;
    this.filterBrands = props.filterBrands;
    this.flightBrand = props.flightBrand;
    this.hasBeenRendered = props.hasBeenRendered ?? false;
  }

  /**
   * Converts the object in a JSON-serializable data structure that should be
   * used **unmodified** in the constructor to create a new instance of this class.
   * This method should be used in conjunction with `new TopOfMenuRow()` to recreate the instance.
   *
   * This function is especially helpful in a server-side environment to
   * keep track of the same instance across client side and server side
   * rendered components.
   * @example
   * ```jsx
   * let serializedObj = obj.serialize();
   * let deserializedObj = new TopOfMenuRow(serializedObj);
   * ```
   */
  public serialize(): TopOfMenuRowProps {
    return {
      clickEndpoint: this.clickEndpoint,
      clickPayload: this.clickPayload,
      creativeIds: this.creativeIds,
      filterBrands: this.filterBrands,
      flightBrand: this.flightBrand,
      hasBeenRendered: this.hasBeenRendered,
      products: this.products.map((product) => product.serialize()),
      renderEndpoint: this.renderEndpoint,
      renderPayload: this.renderPayload,
      title: this.title,
    };
  }

  /**
   * Converts the object to a JSON string, using `serialize()` behind the
   * scenes. The returned value should be used **unmodified** in the
   * `TopOfMenuRow.fromJSON()` static method.
   * This method should be used in conjunction with `TopOfMenuRow.fromJSON()` to recreate the instance.
   */
  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  /**
   * Converts the previously-serialized object back to an instance of this class.
   * This method should be used in conjunction with `toJSON()` to recreate the instance.
   */
  public static fromJSON(serialized: string): TopOfMenuRow {
    return new TopOfMenuRow(typedJSONParse<TopOfMenuRowProps>(serialized));
  }

  /**
   * Records attribution data for a click on the placement.
   * @example
   * ```jsx
   * function TopOfMenuRowComponent({ placement, onClick }) {
   *   return (<div onclick={onClick}>...</div>);
   * }
   *
   * let client = new JaneDM({ apiKey: 'your-api-key' });
   * let placement = await client.fetchTopOfMenuRow({ storeId: 420 });
   *
   * <TopOfMenuRowComponent placement={placement} onClick={() => placement.click()} />
   * ```
   */
  public click(): Promise<void> {
    return api.post(this.clickEndpoint, this.clickPayload);
  }

  /**
   * Records attribution data for rendering of the placement.
   * @example
   * ```jsx
   * function TopOfMenuRowComponent({ placement }) {
   *   useEffect(() => {
   *     placement.render();
   *   }, []);
   *
   *   return (<div>...</div>);
   * }
   * ```
   */
  public render(): Promise<void> {
    if (this.hasBeenRendered) {
      return Promise.resolve();
    }

    return api.post(this.renderEndpoint, this.renderPayload).then(() => {
      this.hasBeenRendered = true;
    });
  }

  /**
   * When the requested instance does not return products, this method
   * is used to determine if the TopOfMenuRow should still be rendered.
   * This method is for internal use only.
   * @internal
   */
  public isRowInFilterBrands(previousRow: TopOfMenuRow | undefined): boolean {
    if (!previousRow?.filterBrands) return true;

    return previousRow.filterBrands.some(
      (filterBrand) => filterBrand.id === this.flightBrand?.id
    );
  }
}

export class TopOfMenuRowClient {
  protected generateProductClickPayload(
    product: AdProduct,
    kevelToken: string | undefined,
    identifier: JaneDMIdentifiers
  ): TopOfMenuRowProductClickPayload {
    return {
      adToken: product.ad_token,
      clickKind: 'product_click',
      janeDeviceId: identifier.jdid,
      kevelToken: kevelToken,
      myHighD: product.my_high_d,
    };
  }

  private generateClickPayload(
    kevelToken: string,
    identifier: JaneDMIdentifiers
  ): TopOfMenuRowClickPayload {
    return {
      clickKind: 'see_all',
      janeDeviceId: identifier.jdid,
      kevelToken,
    };
  }

  protected generateRenderPayload(
    adProducts: AdProduct[],
    kevelToken: string,
    identifier: JaneDMIdentifiers
  ): TopOfMenuRowRenderPayload {
    const positionedProductIds =
      adProducts?.map((product) => product.product_id) ?? [];
    const adTokens =
      adProducts
        ?.map((product) => product.ad_token)
        .filter((productId): productId is string => Boolean(productId)) ?? [];
    const myHighDs =
      adProducts
        ?.map((product) => product.my_high_d)
        .filter((myHighD): myHighD is string => Boolean(myHighD)) ?? [];

    return {
      adTokens,
      distinctId: identifier.mixpanelDistinctId ?? identifier.jdid,
      janeDeviceId: identifier.jdid,
      kevelToken,
      myHighDs,
      positionedProductIds,
      userId: identifier.userId,
    };
  }

  private generateUrl(options: CreateTopOfMenuRowProps): string {
    const url = `/v1/stores/${options.storeId}/sponsored`;

    const searchParams = new URLSearchParams({
      app_mode: options.appMode,
      jane_device_id: options.identifier.jdid,
      mp_distinct_id:
        options.identifier.mixpanelDistinctId ?? options.identifier.jdid,
      zone: zoneStoreMenu,
    });

    if (options.currentCreativeIds && options.currentCreativeIds.length > 0) {
      searchParams.set(
        'current_creative_ids',
        options.currentCreativeIds.join(',')
      );
    }

    if (options.filters) {
      const filterRootTypes = options.filters.rootTypes?.join(',');

      if (filterRootTypes) {
        searchParams.set('current_root_types', filterRootTypes);
      }

      const filterLineages = options.filters.categories?.join(',');

      if (filterLineages) {
        searchParams.set('current_lineages', filterLineages);
      }
    }

    return generateUrl(options.endpoint, `${url}?${searchParams.toString()}`, {
      apiKey: options.apiKey,
      source: options.source,
      version: options.version,
    });
  }

  public async createTopOfMenuRow(
    props: CreateTopOfMenuRowProps
  ): Promise<TopOfMenuRow> {
    const url = this.generateUrl(props);

    const response = (await api.get(url)) as FetchTopOfMenuRowApiResponse;

    const { clickEndpoint, impressionEndpoint } = generateTrackingUrls(
      props.endpoint,
      { apiKey: props.apiKey, source: props.source, version: props.version }
    );

    const products: TopOfMenuRowProductProps[] =
      response.products?.map((product) => ({
        attributes: {},
        clickEndpoint,
        clickPayload: this.generateProductClickPayload(
          product,
          response.flight?.kevel_token,
          props.identifier
        ),
        productId: product.product_id,
      })) ?? [];

    const clickPayload = this.generateClickPayload(
      response.flight?.kevel_token ?? '',
      props.identifier
    );

    const renderPayload = this.generateRenderPayload(
      response.products ?? [],
      response.flight?.kevel_token ?? '',
      props.identifier
    );

    const title =
      response.flight?.custom_title ?? response.flight?.product_brand.name;

    return new TopOfMenuRow({
      clickEndpoint,
      clickPayload,
      creativeIds: response?.flight?.creative_ids ?? [],
      filterBrands: response?.filter_brands,
      flightBrand: response?.flight?.product_brand,
      products,
      renderEndpoint: impressionEndpoint,
      renderPayload,
      title,
    });
  }
}
