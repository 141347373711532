import { useNavigate } from 'react-router-dom';

import { useShouldShowGold } from '@jane/shared/hooks';
import type { PendingCartProduct, Store } from '@jane/shared/models';
import {
  Card,
  Flex,
  Image,
  JaneGoldIcon,
  Tag,
  Typography,
  useDrawerContext,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import {
  calculateDiscountPercentage,
  formatCurrency,
  postMessageToIframeParent,
  productPhotoMetadata,
} from '@jane/shared/util';

import { useCustomerDispatch, useCustomerSelector } from '../../../../customer';
import { clearTouchedProduct } from '../../../../customer/redux/bundlePossibilities';
import { deleteCartItem, updateCart } from '../../../../customer/redux/cart';
import { displayQuantity } from '../../../../lib/receipt';
import { get } from '../../../../redux-util/selectors';
import { CartDrawerItemActions } from './cartDrawerItemActions';
import {
  CartDrawerItem,
  ProductDetailsContainer,
  StyledTypography,
} from './cartDrawerItems.styles';
import { SpecialsTag } from './specialsTag';

interface CartDrawerCardProps {
  bloomUserExperience?: boolean;
  headless?: boolean;
  product: PendingCartProduct;
  productDetailLink: string;
  productFullPrice: number;
  productPrice: number | null;
}

export const CartDrawerCard = ({
  bloomUserExperience,
  headless,
  product,
  productDetailLink,
  productPrice,
  productFullPrice,
}: CartDrawerCardProps) => {
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery({});
  const dispatch = useCustomerDispatch();
  const { store } = useCustomerSelector(get('store'));

  const { onRequestClose } = useDrawerContext();

  const {
    amount,
    brand,
    brand_discount_label,
    brand_subtype,
    category,
    count,
    id,
    max_cart_quantity,
    name,
    price_id,
    special_title,
  } = product;

  const shouldShowGold =
    useShouldShowGold({ product, store: store as Store }) &&
    !!brand_discount_label;

  const maxCartQuantity = product[`max_cart_quantity_${price_id}`]
    ? product[`max_cart_quantity_${price_id}`]
    : max_cart_quantity;

  const removeProduct = () => {
    dispatch(deleteCartItem(product.id, product.price_id));
  };

  const editProductQuantity = (newCount: number) => {
    dispatch(clearTouchedProduct());
    if (count !== newCount) {
      if (headless) {
        postMessageToIframeParent({
          messageType: 'analyticsEvent',
          payload: {
            name: 'cartItemChangeCount',
            properties: {
              productId: id,
              priceId: price_id,
              count: newCount,
            },
          },
        });
      }
      dispatch(
        updateCart({
          product_id: id,
          count: newCount,
          price_id,
        })
      );
    }
  };

  const { url: photoUrl } = productPhotoMetadata(product);

  const showWeight = price_id !== 'each' && !amount;
  const weightString = showWeight ? `/${displayQuantity(product)}` : '';

  const goToProductDetailPage = () => {
    if (headless) {
      postMessageToIframeParent({
        messageType: 'analyticsEvent',
        payload: {
          name: 'cartItemClickedInCheckout',
          properties: {
            productId: product.id,
            priceId: product.price_id,
            productCount: product.count,
            price: productPrice,
          },
        },
      });
    }
    onRequestClose();
    navigate(productDetailLink);
  };

  const isDiscounted = productFullPrice !== productPrice;

  const discountPercentage = calculateDiscountPercentage(
    productFullPrice,
    productPrice
  );

  return (
    <CartDrawerItem data-testid="cart-item" flat width="100%">
      <Card.Content>
        <Flex flexDirection="column" height="100%">
          <Flex
            flexDirection="row"
            height="100%"
            width="100%"
            minWidth={isMobile ? 'calc(95vw - 80px)' : ''}
          >
            <ProductDetailsContainer
              onClick={goToProductDetailPage}
              ariaLabel="product-image"
              alignSelf={isMobile ? 'flex-start' : ''}
            >
              {bloomUserExperience ? (
                <Flex
                  width={isMobile ? '88px' : '96px'}
                  height={isMobile ? '88px' : '96px'}
                >
                  <Flex position="absolute">
                    <Image
                      src={photoUrl}
                      altText={name}
                      border
                      borderRadius={isMobile ? '16px' : 'rounded'}
                      height={isMobile ? '88px' : '96px'}
                      width={isMobile ? '88px' : '96px'}
                      responsive
                      sizes="thumbnail"
                    />
                  </Flex>
                  {discountPercentage > 0 && (
                    <Flex
                      zIndex={1}
                      position="relative"
                      width="100%"
                      justifyContent="flex-end"
                      p={8}
                    >
                      <Tag
                        label={`-${discountPercentage}%`}
                        color="text-info"
                        background="info"
                      />
                    </Flex>
                  )}
                </Flex>
              ) : (
                <Image
                  src={photoUrl}
                  altText={name}
                  border
                  borderRadius={isMobile ? '16px' : 'rounded'}
                  height={isMobile ? '64px' : '96px'}
                  width={isMobile ? '64px' : '96px'}
                  responsive
                  sizes="thumbnail"
                />
              )}
            </ProductDetailsContainer>

            <ProductDetailsContainer
              onClick={goToProductDetailPage}
              ariaLabel="product-detail"
              mx={16}
              width={isMobile ? '153px' : '172px'}
              flexDirection="column"
            >
              <Typography truncateAt="100%" variant="body-bold">
                {name}
              </Typography>
              <StyledTypography color="text-light">{brand}</StyledTypography>
              <StyledTypography color="text-light">
                {brand_subtype}
                {brand_subtype && category ? ' - ' : ''}
                {category}
              </StyledTypography>
              <StyledTypography color="text-light">
                {formatCurrency(productPrice)}
                {weightString}
              </StyledTypography>

              {special_title && <SpecialsTag title={special_title} />}
              {shouldShowGold && (
                <Flex alignItems="center">
                  <JaneGoldIcon altText="jane-gold-icon" size="sm" />
                  <Typography ml={4}>{brand_discount_label}</Typography>
                </Flex>
              )}
            </ProductDetailsContainer>

            <Flex
              alignSelf="stretch"
              flexDirection="column"
              justifyContent="space-between"
              grow
              shrink={bloomUserExperience}
            >
              {bloomUserExperience ? (
                <Flex
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  flexWrap="wrap"
                >
                  <Typography
                    variant="body-bold"
                    textAlign="right"
                    ml={8}
                    color={isDiscounted ? 'info' : 'inherit'}
                  >
                    {formatCurrency(productPrice * count)}
                  </Typography>
                  {isDiscounted && (
                    <Typography
                      variant="body-bold"
                      textAlign="right"
                      strikeThrough
                      color="text-light"
                      ml={8}
                    >
                      {formatCurrency(productFullPrice * count)}
                    </Typography>
                  )}
                </Flex>
              ) : (
                <Typography variant="body-bold" textAlign="right" ml={16}>
                  {formatCurrency(productPrice * count)}
                </Typography>
              )}
              {!isMobile && (
                <CartDrawerItemActions
                  count={count}
                  name={name}
                  handleEditQuantity={editProductQuantity}
                  handleRemoveProduct={removeProduct}
                  maxQuantity={maxCartQuantity}
                />
              )}
            </Flex>
          </Flex>
          {isMobile && (
            <Flex width="100%" mt={16}>
              <CartDrawerItemActions
                count={count}
                name={name}
                handleEditQuantity={editProductQuantity}
                handleRemoveProduct={removeProduct}
                maxQuantity={maxCartQuantity}
              />
            </Flex>
          )}
        </Flex>
      </Card.Content>
    </CartDrawerItem>
  );
};
