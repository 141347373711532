import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type {
  AppMode,
  FetchCartTopperRowProps,
  FetchMenuRowProps,
  FetchRecommendedRowProps,
  FetchRecommendedSortProps,
  JaneDM,
  SmartSort,
  SmartSortProduct,
} from '@jane/dm/sdk';

import type { DmSdkSource } from '../useDmSdk';
import { useDmSdk } from '../useDmSdk';
import { useGetJaneDMIdentifiers } from '../useGetJaneDMIdentifiers';

interface UseSmartSortResponse {
  fetchNextPage: () => Promise<void>;
  hasNextPage: boolean;
  instance: SmartSort | undefined;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  numHits: number;
  products: SmartSortProduct[];
  searchResultFacets: Record<string, Record<string, number>>;
}

export type UseSmartSortProps<T = unknown> = T & {
  appMode: AppMode;
  distinctId?: string;
  enabled?: boolean;
  jdid?: string;
  source?: DmSdkSource;
};

export const useSmartMagicRow = (
  props: UseSmartSortProps<FetchRecommendedRowProps>
) => {
  const {
    searchAttributes = ['*'],
    appMode,
    jdid,
    distinctId,
    storeId,
    searchFilter,
    searchOptionalFilters,
    source,
  } = props;

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchRecommendedRow({
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
        storeId,
      }),
    [searchFilter, searchOptionalFilters]
  );

  return useSmartSort({ appMode, distinctId, jdid, source }, fetchPlacement);
};

export const useSmartCartTopperRow = (
  props: UseSmartSortProps<FetchCartTopperRowProps>
) => {
  const {
    appMode,
    distinctId,
    jdid,
    storeId,
    cartProductIds,
    searchAttributes = ['*'],
    source,
  } = props;

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchCartTopperRow({
        cartProductIds,
        searchAttributes,
        storeId,
      }),
    [cartProductIds]
  );

  const enabled = useMemo(() => cartProductIds.length !== 0, [cartProductIds]);

  return useSmartSort(
    { appMode, distinctId, enabled, jdid, source },
    fetchPlacement
  );
};

export const useRecommendedSort = (
  props: UseSmartSortProps<FetchRecommendedSortProps>
) => {
  const {
    appMode,
    enabled,
    jdid,
    disableAds,
    distinctId,
    maxProducts,
    numColumns,
    pageSize,
    searchAttributes = ['*'],
    searchFacets = ['*'],
    searchFilter,
    searchOptionalFilters,
    searchQuery = '',
    searchSort,
    storeId,
    source,
  } = props;

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchRecommendedSort({
        disableAds,
        maxProducts,
        numColumns,
        pageSize,
        searchAttributes,
        searchFacets,
        searchFilter,
        searchOptionalFilters,
        searchQuery,
        searchSort,
        storeId,
      }),
    [searchFilter, searchQuery, searchSort]
  );

  return useSmartSort(
    { appMode, distinctId, enabled, jdid, source },
    fetchPlacement
  );
};

export const useSmartMenuRow = (
  props: UseSmartSortProps<FetchMenuRowProps>
) => {
  const {
    appMode,
    enabled,
    jdid,
    disableAds,
    distinctId,
    numColumns,
    searchAttributes = ['*'],
    searchFilter,
    searchOptionalFilters,
    searchQuery = '',
    searchSort,
    storeId,
    source,
  } = props;

  const fetchPlacement = useCallback(
    async (sdk: JaneDM) =>
      await sdk.fetchMenuRow({
        disableAds,
        numColumns,
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
        searchQuery,
        searchSort,
        storeId,
      }),
    [searchSort]
  );

  return useSmartSort(
    { appMode, distinctId, enabled, jdid, source },
    fetchPlacement
  );
};

const useSmartSort = (
  props: UseSmartSortProps,
  fetchPlacement: (sdk: JaneDM) => Promise<SmartSort>
): UseSmartSortResponse => {
  const { appMode, distinctId, jdid, source, enabled = true } = props;

  const sdk = useDmSdk({
    appMode,
    identifier: useGetJaneDMIdentifiers({
      jdid,
      mixpanelDistinctId: distinctId,
    }),
    source,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const smartSortRef = useRef<SmartSort | undefined>(undefined);

  const fetchPlacements = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const response = enabled ? await fetchPlacement(sdk) : undefined;
      smartSortRef.current = response;
      setIsSuccess(true);
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [fetchPlacement, enabled]);

  const fetchNextPage = useCallback(async () => {
    if (smartSortRef.current && smartSortRef.current.hasNextPage()) {
      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);
      try {
        await smartSortRef.current.nextPage();
        setIsSuccess(true);
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchPlacements();
  }, [fetchPlacements]);

  return {
    fetchNextPage,
    hasNextPage: smartSortRef.current?.hasNextPage() ?? false,
    instance: smartSortRef.current,
    isError,
    isLoading,
    isSuccess,
    numHits: smartSortRef.current?.totalProducts ?? 0,
    products: smartSortRef.current?.products ?? [],
    searchResultFacets: smartSortRef.current?.searchFacets ?? {},
  };
};
