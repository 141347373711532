import { getReservationModeLabel } from '@jane/shared-ecomm/util';
import type { Store, StoreSpecial } from '@jane/shared/models';
import { Box, Flex, Image, Typography } from '@jane/shared/reefer';
import type {
  Breadcrumb,
  StoreSpecial as zStoreSpecial,
} from '@jane/shared/types';
import {
  getDiscountLabel,
  getSpecialType,
  hasSingleReservationMode,
} from '@jane/shared/util';

import { JumboDiscountBadge } from '../jumboDiscountBadge';
import { StyledFlex, StyledLink } from './specialCard.styles';

export interface SpecialCardProps {
  breadcrumbs?: Breadcrumb[];
  onClick?: () => void;
  special: StoreSpecial | zStoreSpecial;
  store: Store;
  to?: string;
}

export const SpecialCard = ({
  breadcrumbs,
  to,
  onClick,
  special,
  store,
}: SpecialCardProps) => {
  const reservationMode = hasSingleReservationMode(special?.reservation_modes);
  const specialAvailability =
    reservationMode &&
    getReservationModeLabel(store?.reservation_mode_labels, reservationMode);

  return (
    <StyledLink
      to={to}
      {...(breadcrumbs && { state: { breadcrumbs } })}
      onClick={onClick}
      ariaLabel={special.title}
      variant="minimal"
      data-testid="special-card"
    >
      <StyledFlex
        flexDirection="column"
        width="100%"
        border="transparent-black-ten"
        borderRadius="sm"
        overflow="hidden"
        height="240px"
      >
        {special.photo?.urls?.original ? (
          <Image
            height="160px"
            width="100%"
            altText={special.title}
            src={special.photo?.urls?.original}
            sizes="card-lg"
            responsive
          />
        ) : (
          <Flex
            height="160px"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <JumboDiscountBadge color="info" textColor="text-info">
              {getDiscountLabel(special, store)}
            </JumboDiscountBadge>
          </Flex>
        )}
        <Box px={24} py={16} width="100%">
          <Flex justifyContent="space-between">
            <Typography color="info" variant="mini-bold">
              {getSpecialType(special.special_type, store)}
            </Typography>
            {specialAvailability && (
              <Typography variant="mini" color="text-light">
                {specialAvailability} only
              </Typography>
            )}
          </Flex>
          <Typography variant="body-bold" whiteSpace="nowrap" truncateAt="100%">
            {special.title}
          </Typography>
        </Box>
      </StyledFlex>
    </StyledLink>
  );
};
