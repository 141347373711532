import { useMemo } from 'react';

import { useGoldConfig, useUserSegmentIds } from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { BrandSpecial } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import type { ProductWithBrandSpecialSegments } from '@jane/shared/util';

import { checkLocationAndStoreId, checkUserSegments } from './utils';

export interface ShouldShowGoldProps {
  brandSpecial?: Pick<BrandSpecial, 'rules'>;
  brandSpecials?: Pick<BrandSpecial, 'rules'>[];
  product?: ProductWithBrandSpecialSegments;
  store?: {
    id?: string | number;
    state?: string | null;
  };
}

export const useShouldShowGold = (props?: ShouldShowGoldProps): boolean => {
  const { appMode } = useEcommApp();
  const goldFlag = useFlag(FLAGS.janeGold);
  const goldConfigFlag = useFlag(FLAGS.janeGoldConfig);
  const goldSegmentationFlag = useFlag(FLAGS.janeGoldUserSegmentation);

  const { data: goldConfig, isLoading: isLoadingGoldConfig } = useGoldConfig({
    enabled: !!goldConfigFlag,
  });

  const userSegments = useUserSegmentIds({
    enabled: !!props?.product || !!props?.brandSpecial,
    staleTime: 24 * 60 * 60 * 1000,
  });

  return useMemo(() => {
    if (goldConfigFlag && isLoadingGoldConfig) return false;

    // If janeGoldUserSegmentation flag is on, check user segments
    const validSegments = goldSegmentationFlag
      ? checkUserSegments({
          ...props,
          userSegments: userSegments || [],
        })
      : true;

    // If janeGoldConfig flag is on, check location and store ID and ignore
    // the original janeGold flag
    if (goldConfigFlag && goldConfig) {
      const validState = checkLocationAndStoreId(
        goldConfig,
        appMode,
        props?.store
      );

      return validState && validSegments;
    }

    // If the janeGoldConfig flag is off, use the original janeGold flag
    return goldFlag && validSegments;
  }, [
    appMode,
    goldConfig,
    goldConfigFlag,
    goldFlag,
    goldSegmentationFlag,
    isLoadingGoldConfig,
    props,
    userSegments,
  ]);
};
