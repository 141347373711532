import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import type { NavigateFunction } from 'react-router-dom';
import type { Reducer } from 'redux';

import type {
  AddedOrUpdatedProductInCart,
  UserPreferencesType,
} from '@jane/shared-ecomm/tracking';
import {
  EventNames,
  convertAd,
  extractTrackingTags,
  track,
  trackUserSessionAddedProductToCart,
} from '@jane/shared-ecomm/tracking';
import { trackBranchCheckout } from '@jane/shared-ecomm/util';
import type {
  Address,
  AppMode,
  BundlePossibilities,
  Cart,
  CrmProviderType,
  CrmRedemption,
  DeepReadonly,
  Id,
  MenuProduct,
  PendingCartProduct,
  PriceId,
  ReservationMode,
  Store,
  StoreSpecial,
  _DeepReadonlyObject,
} from '@jane/shared/models';
import type { UserSegmentIds } from '@jane/shared/types';
import {
  Storage,
  clientTimeZone,
  currencyCodeFromCountryCode,
  formatUnixTimeWithTimezone,
  getActualUnitPrice,
  prepareCartProduct,
} from '@jane/shared/util';

import { openModal } from '../../common/redux/application';
import {
  pickAnalyticsProductProperties,
  postAnalyticsEvent,
  postCheckoutAnalyticsEvent,
} from '../../lib/analyticsDispatching';
import type { CartLineItems } from '../../lib/getCartLineItems';
import { paths } from '../../lib/routes';
import { createSimpleAction, createStandardAction } from '../../redux-util';
import { NotificationsService } from '../../services/notifications';
import type { CheckoutRequest, CheckoutResponse } from '../../sources/cart';
import { CartSource } from '../../sources/cart';
import { CartProductsSource } from '../../sources/cartProducts';
import {
  applyCrmRedemption as applyCrmRedemptionSource,
  removeCrmRedemption as removeCrmRedemptionSource,
} from '../../sources/crmIntegration';
import type { HeadlessCartProduct } from '../../types/headlessCart';
import { trackCartEvent, trackCheckoutSuccess } from '../cart';
import type { CustomerThunkAction, configureCustomerStore } from '../redux';
import {
  getBundlePossibilities,
  setTouchedProduct,
} from './bundlePossibilities';
import { getPosCart } from './posCart';
import { applyPromoCodeSuccess, resetPromoCodeForm } from './promoCodeForm';
import { isNoStore } from './store';
import type { CustomerAction, CustomerDispatch } from './types';

export const OPEN_CART = 'cart/open';
export const SET_HEADLESS_V2_CART = 'cart/set_headless_v2';

export const openCart = createSimpleAction(OPEN_CART);
export const setHeadlessV2Cart = createSimpleAction(SET_HEADLESS_V2_CART);
export const CLOSE_CART = 'cart/close';
export const closeCart = createSimpleAction(CLOSE_CART);
export const isEmptyCart = (cart: Cart) =>
  isEmpty(cart) || cart.uuid === '' || cart.products?.length === 0;

export const GET_CART = 'cart/get';
export const getCart = (): CustomerThunkAction => (dispatch, getState) => {
  const {
    embeddedApp: { appMode, partnerId },
  } = getState();

  dispatch({ type: GET_CART });

  const getError = (err: any) => dispatch(getCartError(err));
  const getSuccess = ({ cart }: { cart: Cart }) =>
    dispatch(getLocalCartSuccess(cart));

  CartSource.getLocal(appMode, partnerId).then(getSuccess, getError);
};

export const GET_REMOTE_CART = 'cart/get-remote';
export const getRemoteCart =
  (uuid: string): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: GET_REMOTE_CART });

    const getError = (err: any) => dispatch(getCartError(err));
    const getRemoteCartSuccess = (result: { cart: Cart }) => {
      dispatch(getCartSuccess(result));
      dispatch({ type: GET_REMOTE_CART_SUCCESS });
    };

    CartSource.getRemote(uuid).then(getRemoteCartSuccess, getError);
  };

const cartExpired = (cartState: CartState) => {
  const twoSecondsAgo = Date.now() - 2000;
  return (
    !cartState.wasLoadedAt || twoSecondsAgo > cartState.wasLoadedAt.getTime()
  );
};

export const GET_LOCAL_CART_SUCCESS = 'cart/get-local-success';
export const getLocalCartSuccess =
  (cart: Cart): CustomerThunkAction =>
  (dispatch, getState) => {
    dispatch({ type: GET_LOCAL_CART_SUCCESS });
    const { cart: cartState } = getState();

    const getError = (err: any) => dispatch(getCartError(err));
    const getSuccess = (result: { cart: Cart }) => {
      dispatch(getCartSuccess(result));
      dispatch({ type: GET_REMOTE_CART_SUCCESS });
    };

    if (isEmptyCart(cart)) {
      return dispatch(getCartSuccess({ cart }));
    }

    if (cartExpired(cartState)) {
      CartSource.getRemote(cart.uuid, cartState.deliveryAddress).then(
        getSuccess,
        getError
      );
    } else {
      dispatch(getCartSuccess({ cart: cartState.cart }));
    }
  };

export const GET_REMOTE_CART_SUCCESS = 'cart/get-remote-success';
export const GET_CART_SUCCESS = 'cart/get-success';
export const getCartSuccess =
  (result: { cart: Cart }): CustomerThunkAction =>
  (dispatch, getState) => {
    const { bundlePossibilities } = getState();

    dispatchGetCartSuccessAction(
      GET_CART_SUCCESS,
      result,
      bundlePossibilities,
      dispatch
    );
  };

export const REFRESH_CART = 'cart/refresh-cart';
export const refreshCart =
  (cart: Cart): CustomerThunkAction =>
  (dispatch, getState) => {
    const { cart: cartState } = getState();

    const getError = (err: any) => dispatch(getCartError(err));
    const getSuccess = (result: { cart: Cart }) => {
      dispatch({ payload: result, type: REFRESH_CART });
    };

    CartSource.getRemote(cart.uuid, cartState.deliveryAddress).then(
      getSuccess,
      getError
    );
  };

export const GET_USER_CART_SUCCESS = 'cart/get-user-cart-success';

const dispatchGetCartSuccessAction = (
  action: typeof GET_CART_SUCCESS | typeof GET_USER_CART_SUCCESS,
  result: { cart: Cart },
  bundlePossibilities: {
    bundlePossibilities: BundlePossibilities;
    filterDiscountableProducts: boolean;
    showingBundlePossibilities: boolean;
    touchedProductId?: Id;
  },
  dispatch: CustomerDispatch
) => {
  const { cart } = result;
  dispatch({ payload: result, type: action });
  if (isEmptyCart(cart)) {
    return dispatch(clearCart());
  }

  if (cart.promo_code) {
    dispatch(applyPromoCodeSuccess());
  }

  if (
    bundlePossibilities.touchedProductId &&
    bundlePossibilities.showingBundlePossibilities
  ) {
    dispatch(getBundlePossibilities(cart.uuid));
  }
};

export const getUserCartSuccess =
  (result: { cart: Cart }): CustomerThunkAction =>
  (dispatch, getState) => {
    const { bundlePossibilities } = getState();

    dispatchGetCartSuccessAction(
      GET_USER_CART_SUCCESS,
      result,
      bundlePossibilities,
      dispatch
    );
  };

export const getCartForUser =
  (): CustomerThunkAction => (dispatch, getState) => {
    const {
      cart: {
        cart: { uuid },
      },
      embeddedApp: { appMode, partnerId },
      headlessApp,
    } = getState();

    dispatch({ type: GET_CART });

    const getSuccess = ({ cart }: { cart: Cart }) =>
      dispatch(getUserCartSuccess({ cart }));
    const getError = (err: any) => dispatch(getCartError(err));

    const storeId = headlessApp?.headlessStoreId ?? partnerId;

    CartSource.getForUser(appMode, storeId, uuid).then(getSuccess, getError);
  };

// checks local storage & clears any existing cart. used in app modes like BrandEmbed where old carts aren't supported
export const resetCart = (): CustomerThunkAction => (dispatch, getState) => {
  const {
    embeddedApp: { appMode, partnerId },
  } = getState();

  dispatch({ type: GET_CART });

  const getSuccess = ({ cart }: { cart: Cart }) => {
    dispatch(getCartSuccess({ cart }));

    dispatch(clearCart());
  };
  const getError = (err: any) => dispatch(getCartError(err));

  CartSource.getLocal(appMode, partnerId).then(getSuccess, getError);
};

export const GET_CART_ERROR = 'cart/get-error';
export const getCartError = createStandardAction(GET_CART_ERROR)<unknown>();

export const WILL_REPLACE_SERVER_CART = 'cart/will-replace-server-cart';
export const willReplaceServerCart = createSimpleAction(
  WILL_REPLACE_SERVER_CART
);

export const CART_CLEAR = 'cart/clear';
export const clearCart =
  (preserveRemoteCart?: boolean): CustomerThunkAction<Promise<unknown>> =>
  (dispatch, getState) => {
    const {
      cart: { cart },
      embeddedApp: { appMode, partnerId },
    } = getState();

    dispatch({ type: CART_CLEAR });
    dispatch(resetPromoCodeForm());

    if (isEmptyCart(cart)) return Promise.resolve();

    const clearCartSuccessHandler = () => {
      Storage.remove('cart');
      Storage.remove(cartStorageKey(appMode, partnerId));
    };

    const clearCartErrorHandler = () => dispatch(clearCartError);

    const promise = preserveRemoteCart
      ? Promise.resolve()
      : CartSource.delete(cart.uuid);

    return promise.then(clearCartSuccessHandler, clearCartErrorHandler);
  };

export const CART_CLEAR_ERROR = 'cart/clear-error';
export const clearCartError = createStandardAction(CART_CLEAR_ERROR)<unknown>();

export const CART_DELETE_ITEM = 'cart/delete-item';
export const deleteCartItem =
  (productId: Id, priceId: PriceId): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      bundlePossibilities,
      cart: cartState,
      store: storeState,
    } = getState();
    const { cart } = cartState;

    const product: PendingCartProduct | undefined = cart.products.find(
      (product) => product.id === productId && product.price_id === priceId
    );

    const store = storeState?.store;
    let storeCurrency = undefined;
    if (store && !isNoStore(store)) {
      storeCurrency = currencyCodeFromCountryCode(
        (store as Store).country_code
      );
    }

    if (product) {
      const { name, category, brand, count } = product;
      const pricePerUnit =
        getActualUnitPrice(product as MenuProduct, product.price_id) || 0;
      track({
        event: EventNames.RemovedProductFromCart,
        productBrand: brand,
        productCategory: category,
        productId: productId.toString(),
        productName: name,
        productPrice: pricePerUnit,
        quantity: count,
        storeCurrency,
      });
    }

    if (bundlePossibilities.showingBundlePossibilities) {
      dispatch(setTouchedProduct(undefined));
    }

    CartProductsSource.delete(productId, priceId, cart.uuid)
      .then(() => {
        dispatch({ payload: { priceId, productId }, type: CART_DELETE_ITEM });

        // only dispatch events after we are sure the request was successful
        postAnalyticsEvent({
          eventName: 'cartItemRemoval',
          eventPayload: {
            priceId,
            productId,
          },
        });

        if (cart.products.length === 1) {
          dispatch(resetPromoCodeForm());
          postAnalyticsEvent({
            eventName: 'cartEmptied',
          });
        }

        // do not re-fetch cart if last product was just removed; cart will already have been deleted
        if (cart.products.length > 1) {
          dispatch(
            analyzeSpecials({
              promoCode: cart.promo_code,
              reservationMode: cart.reservation_mode,
              userGroupSpecialId: cartState.userGroupSpecialId,
            })
          );
        }
      })
      .catch((err) => {
        const action = matchError(err);
        dispatch(action);
      });
  };

export const CART_UPDATE_PROMO_CODE = 'cart/update-promo-code';
export const updateQueryPromoCode =
  (queryPromoCode: string | null): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      embeddedApp: { appMode, partnerId },
    } = getState();
    const storageKey = `${cartStorageKey(appMode, partnerId)}-promo-code`;

    const storedPromoCode = Storage.get(storageKey);
    const promoCode = queryPromoCode || storedPromoCode || undefined;

    dispatch({
      payload: promoCode,
      type: CART_UPDATE_PROMO_CODE,
    });

    // Clear promo code once it's applied to cart
    if (queryPromoCode === null) {
      Storage.remove(storageKey);
    }
  };

export const CART_UPDATE_TAGS = 'cart/update-tags';
export const updateTags =
  (queryParams: {}): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      embeddedApp: { appMode, partnerId },
    } = getState();

    const storedTags = Storage.get(
      `${cartStorageKey(appMode, partnerId)}-tags`
    );
    const urlTags = extractTrackingTags(queryParams);
    const payload = !isEmpty(urlTags)
      ? urlTags
      : !isEmpty(storedTags)
      ? storedTags
      : undefined;

    dispatch({
      payload,
      type: CART_UPDATE_TAGS,
    });
  };

export const CART_UPDATE_TIP = 'cart/update-tip';
export const updateTip = createStandardAction(CART_UPDATE_TIP)<string>();

export const REMOVE_SPECIAL_HAS_CHANGED_FLAG =
  'cart/remove-special-has-changed-flag';
export const removeSpecialHasChangedFlag =
  (): CustomerThunkAction => (dispatch, getState) => {
    const { cart: cartState } = getState();
    dispatch({ type: REMOVE_SPECIAL_HAS_CHANGED_FLAG });
    CartSource.removeSpecialHasChangedFlag(cartState.cart.uuid).then(() =>
      dispatch(removeSpecialHasChangedFlagSuccess())
    );
  };

export const REMOVE_SPECIAL_HAS_CHANGED_FLAG_SUCCESS =
  'cart/remove-special-has-changed-flag-success';
export const removeSpecialHasChangedFlagSuccess = createSimpleAction(
  REMOVE_SPECIAL_HAS_CHANGED_FLAG_SUCCESS
);

export const REMOVE_BRAND_SPECIAL_HAS_CHANGED_FLAG_SUCCESS =
  'cart/remove-brand-speical-has-changed-flag-success';
export const removeBrandSpecialHasChangedFlag = createSimpleAction(
  REMOVE_BRAND_SPECIAL_HAS_CHANGED_FLAG_SUCCESS
);

export const DELETE_UNAVAILABLE_PRODUCTS = 'cart/delete-unavailable-products';
export const deleteUnavailableProducts =
  (productIds: Id[]): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      cart: { cart },
    } = getState();

    if (!cart.uuid) {
      return;
    }

    dispatch({ type: DELETE_UNAVAILABLE_PRODUCTS });

    CartProductsSource.deleteUnavailableProducts(cart.uuid).then(() => {
      dispatch(deleteUnavailableProductsSuccess(productIds));
    });
  };

export const DELETE_UNAVAILABLE_PRODUCTS_SUCCESS =
  'cart/delete-unavailable-products-success';
export const deleteUnavailableProductsSuccess = createStandardAction(
  DELETE_UNAVAILABLE_PRODUCTS_SUCCESS
)<Id[]>();

export const CLEAR_REMOVED_CRM_REDEMPTIONS =
  'cart/clear-removed-crm-redemptions';
export const clearRemovedCrmRedemptions = createSimpleAction(
  CLEAR_REMOVED_CRM_REDEMPTIONS
);

export const replaceCart =
  (replacementCartProduct: StoreAndCartProduct): CustomerThunkAction =>
  (dispatch) => {
    dispatch(clearCart()).then(() => {
      dispatch(addToCart(replacementCartProduct));
    });
  };

export const DISMISS_CHECKOUT_ERROR = 'cart/dismiss-checkout-error';
export const dismissCheckoutError = createSimpleAction(DISMISS_CHECKOUT_ERROR);

export const removeCartUser =
  (): CustomerThunkAction => (dispatch, getState) => {
    const {
      cart: { cart, deliveryAddress },
    } = getState();
    if (cart.uuid === '') {
      return;
    }

    CartSource.update({
      delivery_address: deliveryAddress,
      user_id: null,
      uuid: cart.uuid,
    }).then((result) => {
      dispatch(getCartSuccess(result));
    });
  };

export const CART_UPDATE_RESERVATION_MODE = 'cart/update-reservation-mode';
export const updateReservationMode =
  ({
    reservationMode,
    userGroupSpecialId,
  }: {
    reservationMode: ReservationMode;
    userGroupSpecialId?: Id;
  }): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      cart: { cart, deliveryAddress },
    } = getState();
    if (!cart.uuid) return;

    dispatch({
      payload: { reservationMode, userGroupSpecialId },
      type: CART_UPDATE_RESERVATION_MODE,
    });

    CartSource.update({
      delivery_address: deliveryAddress,
      reservation_mode: reservationMode,
      user_group_special_id: userGroupSpecialId,
      uuid: cart.uuid,
    }).then((result) => {
      dispatch({ payload: result, type: GET_CART_SUCCESS });

      // we also need to refresh the pos cart calculation
      dispatch(getPosCart());
    });
  };

export const UPDATE_RESERVATION_MODE_SUCCESS =
  'cart/update-reservation-mode-success';
export const updateReservationModeSuccess = createStandardAction(
  UPDATE_RESERVATION_MODE_SUCCESS
)<{ cart: Cart }>();

export const CART_UPDATE_DELIVERY_ADDRESS = 'cart/update-delivery-address';
export const updateDeliveryAddress =
  ({ deliveryAddress }: { deliveryAddress: Address }): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      cart: { cart },
    } = getState();

    if (deliveryAddress.street?.length > 0) {
      dispatch({
        payload: { deliveryAddress },
        type: CART_UPDATE_DELIVERY_ADDRESS,
      });

      if (cart.uuid) {
        CartSource.getRemote(cart.uuid, deliveryAddress).then((result) => {
          dispatch({ payload: result, type: GET_CART_SUCCESS });
        });
      }
    }
  };

export const CART_REMOVE_DELIVERY_ADDRESS = 'cart/remove-delivery-address';
export const removeDeliveryAddress = (): CustomerThunkAction => (dispatch) => {
  dispatch({ type: CART_REMOVE_DELIVERY_ADDRESS });
};

export const ANALYZE_SPECIALS = 'cart/analyze-specials';
export const analyzeSpecials =
  ({
    userGroupSpecialId,
    reservationMode,
    promoCode,
  }: {
    promoCode: string | undefined;
    reservationMode: ReservationMode;
    userGroupSpecialId: Id | undefined;
  }): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      bundlePossibilities,
      cart: { cart, deliveryAddress },
    } = getState();

    if (isEmptyCart(cart)) {
      return;
    }

    dispatch({ type: ANALYZE_SPECIALS });

    return CartSource.optimizeSpecials({
      delivery_address: deliveryAddress,
      promo_code: promoCode,
      reservation_mode: reservationMode,
      user_group_special_id: userGroupSpecialId,
      uuid: cart.uuid,
    }).then(
      ({ cart }) => {
        dispatch(analyzeSpecialsSuccess({ cart, userGroupSpecialId }));

        if (
          bundlePossibilities.touchedProductId &&
          bundlePossibilities.showingBundlePossibilities
        ) {
          dispatch(getBundlePossibilities(cart.uuid));
        }
      },
      (err) => dispatch(getCartError(err))
    );
  };

export const ANALYZE_SPECIALS_SUCCESS = 'cart/analyze-specials-success';
export const analyzeSpecialsSuccess = createStandardAction(
  ANALYZE_SPECIALS_SUCCESS
)<{ cart: Cart; userGroupSpecialId?: Id }>();

export interface CrmParams extends CrmRedemption {
  crm_member_points: number;
}

export const APPLY_CRM_REDEMPTION = 'cart/apply-crm-redemption';
export const applyCrmRedemption =
  (crmParams: CrmParams): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      cart: { cart, deliveryAddress },
    } = getState();

    dispatch({ type: APPLY_CRM_REDEMPTION });

    applyCrmRedemptionSource(
      {
        ...crmParams,
        cart_uuid: cart.uuid,
      },
      deliveryAddress
    ).then(
      (result) => {
        if (result.error_message) {
          dispatch(applyCrmRedemptionError());
          return;
        }

        dispatch(updateCrmRedemptions({ cart: result.cart }));
      },
      () => dispatch(applyCrmRedemptionError())
    );
  };

export const UPDATE_CRM_REDEMPTIONS = 'cart/update-crm-redemptions';
export const updateCrmRedemptions = createStandardAction(
  UPDATE_CRM_REDEMPTIONS
)<{ cart: Cart }>();

export const APPLY_CRM_REDEMPTION_ERROR = 'cart/apply-crm-redemption-error';
export const applyCrmRedemptionError = createSimpleAction(
  APPLY_CRM_REDEMPTION_ERROR
);

export const REMOVE_CRM_REDEMPTION = 'cart/remove-crm-redemption';
export const removeCrmRedemption =
  (id: Id, crmProvider: CrmProviderType): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      cart: { cart, userGroupSpecialId },
    } = getState();

    removeCrmRedemptionSource(id, crmProvider).then(
      () => {
        dispatch(removeCrmRedemptionSuccess({ id }));
        dispatch(
          analyzeSpecials({
            promoCode: cart.promo_code,
            reservationMode: cart.reservation_mode,
            userGroupSpecialId,
          })
        );
      },
      () => dispatch(removeCrmRedemptionError())
    );
  };

export const REMOVE_CRM_REDEMPTION_SUCCESS =
  'cart/remove-crm-redemption-success';
export const removeCrmRedemptionSuccess = createStandardAction(
  REMOVE_CRM_REDEMPTION_SUCCESS
)<{ id: Id }>();

export const REMOVE_CRM_REDEMPTION_ERROR = 'cart/remove-crm-redemption-error';
export const removeCrmRedemptionError = createSimpleAction(
  REMOVE_CRM_REDEMPTION_ERROR
);

export const PAYTENDER_CHECKOUT_BEGIN = 'cart/paytender-checkout-begin';
export const paytenderCheckoutBegin = createSimpleAction(
  PAYTENDER_CHECKOUT_BEGIN
);

export const PAYTENDER_CHECKOUT_CANCELLED = 'cart/paytender-checkout-cancelled';
export const paytenderCheckoutCancelled = createSimpleAction(
  PAYTENDER_CHECKOUT_CANCELLED
);

export const PAYTENDER_CHECKOUT_SUCCESS = 'cart/paytender-checkout-success';
export const paytenderCheckoutSuccess =
  ({
    isEmbeddedMode,
    totalAmount,
    navigate,
  }: {
    isEmbeddedMode: boolean;
    navigate: NavigateFunction;
    totalAmount: number;
  }): CustomerThunkAction =>
  (dispatch, getState) => {
    dispatch(resetPromoCodeForm());
    const {
      cart: { cart, deliveryAddress },
      customer,
    } = getState();
    const cartId = `${cart.id}` || '';

    const { firstName, lastName, phone, email, birth_date } = customer;

    postCheckoutAnalyticsEvent({
      cart,
      cartLineItems: {
        total: totalAmount,
      },
      deliveryAddress,
      orderCustomer: {
        birthDate: birth_date,
        email,
        firstName,
        lastName,
        phone,
      },
    });
    trackCheckoutSuccess({ cart });

    const reservationPath = isEmbeddedMode
      ? paths.embeddedReservation(cartId)
      : paths.reservation(cartId);

    navigate(reservationPath);
    dispatch({ type: PAYTENDER_CHECKOUT_SUCCESS });
  };

export const SET_USER_GROUP_SPECIAL_ID = 'cart/set-user-group-special-id';
export const setUserGroupSpecialId = createStandardAction(
  SET_USER_GROUP_SPECIAL_ID
)<{
  id: number | undefined;
}>();

export const FIX_UNAVAILABLE_CHECKOUT = 'cart/fix-unavailable-checkout';
export const fixUnavailableCheckout = createSimpleAction(
  FIX_UNAVAILABLE_CHECKOUT
);

export const UNAVAILABLE_CHECKOUT_FIXED = 'cart/unavailable-checkout-fixed';
export const unavailableCheckoutFixed = createSimpleAction(
  UNAVAILABLE_CHECKOUT_FIXED
);

const handleChangedCartError = (err: string, dispatch: CustomerDispatch) => {
  if (err.indexOf('Your total has been updated') > -1) {
    dispatch(getCart());
  }
  if (
    err.indexOf('One or more products in your cart are no longer available') >
    -1
  ) {
    dispatch(getCart());
    dispatch(fixUnavailableCheckout());
  }
};

export const CHECKOUT = 'cart/checkout';
export const checkout =
  ({
    checkout,
    isEmbeddedMode,
    isGuest,
    cartLineItems,
    navigate,
  }: {
    cartLineItems: CartLineItems;
    checkout: CheckoutRequest;
    isEmbeddedMode: boolean;
    isGuest: boolean;
    navigate: NavigateFunction;
  }): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: CHECKOUT });

    CartSource.save(checkout)
      .then(
        (result: CheckoutResponse) => {
          dispatch(
            checkoutSuccess({
              cartLineItems,
              isEmbeddedMode,
              isGuest,
              navigate,
              result,
              paymentMethod: checkout.payment_method,
            })
          );
        },
        (err) => {
          dispatch(checkoutError(err));
          handleChangedCartError(err, dispatch);
        }
      )
      .catch((err) => {
        dispatch(checkoutError(err));
      });
  };

export const CHECKOUT_SUCCESS = 'cart/checkout-success';
export const checkoutSuccess =
  ({
    result: { message, reservation, contact, warning_message },
    isEmbeddedMode,
    cartLineItems,
    isGuest,
    navigate,
    paymentMethod,
  }: {
    cartLineItems: CartLineItems;
    isEmbeddedMode: boolean;
    isGuest: boolean;
    navigate: NavigateFunction;
    paymentMethod?: string;
    result: CheckoutResponse;
  }): CustomerThunkAction =>
  (dispatch, getState) => {
    dispatch(resetPromoCodeForm());
    const {
      cart: { cart, deliveryAddress },
      customer,
      application,
      embeddedApp,
      store,
    } = getState();
    const { products, uuid } = cart;
    trackBranchCheckout(uuid);

    convertAd({
      appMode: embeddedApp.appMode,
      cartId: cart.id,
      janeDeviceId: application.janeDeviceId,
      products: products as PendingCartProduct[],
      storeId: cart.store?.id,
    });

    const { firstName, lastName, phone, email, birth_date } = customer;
    const total = Number(reservation.amount);

    const tz = isNoStore(store.store)
      ? clientTimeZone()
      : store.store.time_zone_identifier;
    const delivery_finished_at = reservation.delivery_finished_at
      ? formatUnixTimeWithTimezone(reservation.delivery_finished_at, '', tz)
      : undefined;
    const delivery_started_at = reservation.delivery_started_at
      ? formatUnixTimeWithTimezone(reservation.delivery_started_at, '', tz)
      : undefined;

    postCheckoutAnalyticsEvent({
      cart: {
        ...cart,
        delivery_finished_at,
        delivery_started_at,
        message: reservation.message,
        payment_method: reservation.payment_method,
      },
      cartLineItems: {
        ...cartLineItems,
        total,
      },
      deliveryAddress,
      orderCustomer: {
        birthDate: birth_date,
        email,
        firstName,
        lastName,
        phone,
      },
    });

    trackCheckoutSuccess({
      cart,
      paymentMethod,
      store: store?.store,
    });

    if (!warning_message) {
      // TODO: Does this notification even show up?
      NotificationsService.success(message);
    }

    if (isGuest) {
      const { is_registered } = contact || {};
      navigate(
        paths.guestReservationReceipt(reservation.uuid, {
          is_registered,
        })
      );
    } else {
      const urlQuery = {};
      if (warning_message) {
        urlQuery['warning_message'] = warning_message;
      }
      const path = isEmbeddedMode
        ? paths.embeddedReservation(reservation.id, urlQuery)
        : paths.reservation(reservation.id, urlQuery);
      navigate(path);
    }

    dispatch({ payload: reservation, type: CHECKOUT_SUCCESS });
  };

export const CHECKOUT_ERROR = 'cart/CHECKOUT_ERROR';
export const checkoutError = createStandardAction(CHECKOUT_ERROR)<unknown>();

export const CART_UPDATE = 'cart/update';
export const updateCart =
  ({
    product_id,
    count,
    price_id,
  }:
    | PendingCartProduct
    | {
        count: number;
        price_id: PriceId;
        product_id: number;
      }): CustomerThunkAction =>
  (dispatch, getState) => {
    const { cart: cartState, bundlePossibilities } = getState();

    const { cart } = cartState;

    CartProductsSource.update(product_id, count, price_id, cart.uuid)
      .then((result) => {
        dispatch({ payload: result.cart_product, type: CART_UPDATE });

        if (bundlePossibilities.showingBundlePossibilities) {
          dispatch(setTouchedProduct(product_id));
        }
        // NOTE: Cart object gets refetched in this call to analyze specials,
        // but does not replace the entire cart object - see ANALYZE_SPECIALS_SUCCESS
        // case in the reducer below.
        dispatch(
          analyzeSpecials({
            promoCode: cart.promo_code,
            reservationMode: cart.reservation_mode,
            userGroupSpecialId: cartState.userGroupSpecialId,
          })
        );
      })
      .catch((err) => {
        const action = matchError(err);
        dispatch(action);
      });
  };

export const CART_ADD = 'cart/add';
export const addToCart =
  (
    storeProduct: {
      product: PendingCartProduct;
      store: Store;
    },
    withoutDrawer?: boolean
  ): CustomerThunkAction<Promise<unknown>> =>
  (dispatch, getState) => {
    const { embeddedApp, cart, bundlePossibilities, application } = getState();
    return CartProductsSource.add({
      appMode: embeddedApp.appMode,
      appModeName: embeddedApp.appMode,
      cart_uuid: cart.cart.uuid,
      cartProduct: storeProduct.product,
      janeDeviceId: application?.janeDeviceId,
      store: storeProduct.store,
      tags: cart.tags,
    })
      .then((result) => {
        dispatch({
          payload: {
            ...storeProduct,
            cartUuid: result.cart_uuid,
            userAgent: navigator.userAgent,
          },
          type: CART_ADD,
        });

        if (bundlePossibilities.showingBundlePossibilities) {
          dispatch(setTouchedProduct(storeProduct.product.id));
        }

        dispatch(getCart());

        if (embeddedApp.appMode !== 'headless' && !withoutDrawer) {
          dispatch(openCart());
        }
      })
      .catch((err) => {
        const action = matchError(err);
        dispatch(action);
      });
  };

export const PREPARE_TO_REPLACE_CART = 'cart/prepare-to-replace-cart';
export const prepareToReplaceCart = createStandardAction(
  PREPARE_TO_REPLACE_CART
)<CartState['replacementCart']>();

export const CREATE_HEADLESS_CART_SUCCESS = 'cart/create-headless-success';
export const createHeadlessCartSuccess = createStandardAction(
  CREATE_HEADLESS_CART_SUCCESS
)<{ cart: Cart }>();

export const CREATE_HEADLESS_CART_ERROR = 'cart/create-headless-error';
export const createHeadlessCartError = createSimpleAction(
  CREATE_HEADLESS_CART_ERROR
);

export const CREATE_CART = 'cart/create';

export const attemptCreateHeadlessCart =
  ({
    storeId,
    products,
    reservationMode,
    externalSource,
    headlessCheckoutPartnerId,
    loginURL,
    disableGuestCheckout,
  }: {
    disableGuestCheckout?: boolean;
    externalSource?: string;
    headlessCheckoutPartnerId?: string;
    loginURL?: string;
    products: HeadlessCartProduct[];
    reservationMode: 'pickup' | 'delivery';
    storeId: string;
  }): CustomerThunkAction =>
  async (dispatch, getState) => {
    const { embeddedApp, application } = getState();

    dispatch({ type: CREATE_CART });

    const body = {
      app_mode: embeddedApp.appMode,
      app_mode_name: embeddedApp.appMode,
      disable_guest_checkout: disableGuestCheckout,
      external_source: externalSource,
      headless_checkout_partner_id: headlessCheckoutPartnerId,
      login_url: loginURL,
      products,
      reservation_mode: reservationMode,
      store_id: storeId,
      user_agent: navigator.userAgent,
      j_device_id: application?.janeDeviceId,
    };

    CartSource.create(body)
      .then((result: { cart: Cart }) => {
        dispatch(createHeadlessCartSuccess(result));
      })
      .catch(() => {
        dispatch(createHeadlessCartError());
      });
  };

export const ADDING_ITEM_TO_CART = 'cart/adding-item-to-cart';
const ADDING_ITEM_TO_CART_FAILED = 'cart/adding-item-to-cart-failed';

export interface AttemptToAddToCartPayload
  extends Pick<
    AddedOrUpdatedProductInCart,
    'columnPosition' | 'location' | 'placementType' | 'rowPosition' | 'tabName'
  > {
  count: number;
  menuProduct: MenuProduct;
  navigate: NavigateFunction;
  price_id: PriceId;
  source: string;
  special?: StoreSpecial;
  store: Store;
  userPreferences: UserPreferencesType;
  userSegments?: UserSegmentIds;
  withoutDrawer?: boolean;
}

export const attemptToAddToCart =
  ({
    columnPosition,
    count,
    location,
    menuProduct,
    navigate,
    placementType,
    price_id,
    rowPosition,
    tabName,
    source,
    special,
    store,
    userPreferences,
    userSegments,
    withoutDrawer = false,
  }: AttemptToAddToCartPayload): CustomerThunkAction =>
  (dispatch, getState) => {
    const {
      cart: { cart },
      embeddedApp: { appMode },
    } = getState();

    const storeAndProduct = prepareCartProduct(
      store,
      menuProduct,
      price_id,
      count
    );

    if (cart.store && cart.store.id !== store.id) {
      return maybeChangeStore(appMode, dispatch, storeAndProduct, navigate);
    }

    const productInCart = isProductInCart(cart, menuProduct.id, price_id);
    if (productInCart) {
      trackCartEvent({
        count,
        event: EventNames.UpdatedProductInCart,
        location,
        menuProduct,
        price_id,
        source,
        special,
        store,
        userPreferences,
        userSegments,
      });
      dispatch(
        updateCart({
          count,
          price_id,
          product_id: menuProduct.id,
        })
      );
    } else {
      postAnalyticsEvent({
        eventName: 'cartItemAdd',
        eventPayload: {
          product: {
            price_id,
            ...pickAnalyticsProductProperties<_DeepReadonlyObject<MenuProduct>>(
              menuProduct,
              ['product_id', 'name', 'brand', 'category', 'kind']
            ),
          },
          productId: menuProduct.product_id || menuProduct.id,
        },
      });

      trackCartEvent({
        cartId: cart.id,
        columnPosition,
        count,
        event: EventNames.AddedProductToCart,
        location,
        menuProduct,
        placementType,
        price_id,
        rowPosition,
        source,
        special,
        store,
        tabName,
        userPreferences,
        userSegments,
      });
      trackUserSessionAddedProductToCart({
        productId: menuProduct.product_id,
        storeId: store.id,
      });
      dispatch({ type: ADDING_ITEM_TO_CART });
      dispatch(addToCart(storeAndProduct, withoutDrawer));
    }
  };

const maybeChangeStore = (
  appMode: AppMode,
  dispatch: CustomerDispatch,
  storeAndProduct: StoreAndCartProduct,
  navigate: NavigateFunction
) => {
  dispatch(prepareToReplaceCart(storeAndProduct));

  if (appMode === 'default') {
    return dispatch(openModal({ name: 'replaceCart' }));
  }
  return navigate(paths.embeddedReplaceCart());
};

const isProductInCart = (
  cart: DeepReadonly<Cart>,
  productId: Id,
  price_id: PriceId
) =>
  cart.products.find(
    (product) => product.id === productId && product.price_id === price_id
  );

const matchError = (error: string): CartActions => {
  switch (error) {
    case 'Cart is not pending':
      return { type: CART_CLEAR };
  }

  return { type: ADDING_ITEM_TO_CART_FAILED };
};

export interface CartUpdatePayload {
  count: number;
  price_id: PriceId;
  product_id: number;
}

export interface CartAddPayload {
  cartUuid: string;
  product: PendingCartProduct;
  store: Store;
  userAgent: string;
}

export interface CartDeleteItemPayload {
  priceId: PriceId;
  productId: Id;
}

export interface CartUpdateDeliveryAddressPayload {
  deliveryAddress: Address;
}

export type CartActions =
  | { type: typeof OPEN_CART }
  | { type: typeof SET_HEADLESS_V2_CART }
  | { type: typeof CLOSE_CART }
  | { type: typeof ADDING_ITEM_TO_CART }
  | { type: typeof ADDING_ITEM_TO_CART_FAILED }
  | { type: typeof CREATE_CART }
  | { type: typeof GET_CART }
  | { type: typeof GET_REMOTE_CART }
  | { type: typeof GET_REMOTE_CART_SUCCESS }
  | { type: typeof CART_CLEAR }
  | { type: typeof CART_CLEAR_ERROR }
  | { type: typeof WILL_REPLACE_SERVER_CART }
  | {
      payload: CartDeleteItemPayload;
      type: typeof CART_DELETE_ITEM;
    }
  | { payload: {} | undefined; type: typeof CART_UPDATE_TAGS }
  | { payload: string; type: typeof CART_UPDATE_TIP }
  | { payload: string | undefined; type: typeof CART_UPDATE_PROMO_CODE }
  | { type: typeof REMOVE_SPECIAL_HAS_CHANGED_FLAG }
  | { type: typeof DELETE_UNAVAILABLE_PRODUCTS }
  | ReturnType<typeof dismissCheckoutError>
  | {
      payload: {
        reservationMode: ReservationMode;
        userGroupSpecialId?: Id;
      };
      type: typeof CART_UPDATE_RESERVATION_MODE;
    }
  | { type: typeof ANALYZE_SPECIALS }
  | { type: typeof APPLY_CRM_REDEMPTION }
  | ReturnType<typeof applyCrmRedemptionError>
  | ReturnType<typeof updateCrmRedemptions>
  | { payload: Cart; type: typeof REMOVE_CRM_REDEMPTION }
  | ReturnType<typeof removeCrmRedemptionError>
  | ReturnType<typeof removeCrmRedemptionSuccess>
  | { type: typeof FIX_UNAVAILABLE_CHECKOUT }
  | { type: typeof UNAVAILABLE_CHECKOUT_FIXED }
  | { type: typeof CHECKOUT }
  | {
      payload: PendingCartProduct | CartUpdatePayload;
      type: typeof CART_UPDATE;
    }
  | {
      payload: CartAddPayload;
      type: typeof CART_ADD;
    }
  | ReturnType<typeof prepareToReplaceCart>
  | { type: typeof GET_LOCAL_CART_SUCCESS }
  | { payload: { cart: Cart }; type: typeof GET_CART_SUCCESS }
  | { payload: { cart: Cart }; type: typeof GET_USER_CART_SUCCESS }
  | { payload: { cart: Cart }; type: typeof REFRESH_CART }
  | { type: typeof PAYTENDER_CHECKOUT_BEGIN }
  | { type: typeof PAYTENDER_CHECKOUT_SUCCESS }
  | { type: typeof PAYTENDER_CHECKOUT_CANCELLED }
  | { payload: CheckoutResponse['reservation']; type: typeof CHECKOUT_SUCCESS }
  | ReturnType<typeof checkoutError>
  | ReturnType<typeof analyzeSpecialsSuccess>
  | ReturnType<typeof getCartError>
  | ReturnType<typeof deleteUnavailableProductsSuccess>
  | ReturnType<typeof clearRemovedCrmRedemptions>
  | ReturnType<typeof removeSpecialHasChangedFlagSuccess>
  | ReturnType<typeof removeBrandSpecialHasChangedFlag>
  | { payload: { cart: Cart }; type: typeof CREATE_HEADLESS_CART_SUCCESS }
  | { type: typeof CREATE_HEADLESS_CART_ERROR }
  | ReturnType<typeof updateReservationModeSuccess>
  | {
      payload: CartUpdateDeliveryAddressPayload;
      type: typeof CART_UPDATE_DELIVERY_ADDRESS;
    }
  | { type: typeof CART_REMOVE_DELIVERY_ADDRESS }
  | {
      payload: { id: Id | undefined };
      type: typeof SET_USER_GROUP_SPECIAL_ID;
    };

export type StoreAndCartProduct = DeepReadonly<{
  product: PendingCartProduct;
  store: Store;
}>;

export type CartState = DeepReadonly<{
  analyzingSpecials: boolean;
  cart: Cart & { status: string | undefined };
  checkoutError: string | null;
  deliveryAddress: Address | null;
  hasCheckoutError: boolean;
  hasLoaded: boolean;
  isAddingItemToCart: boolean;
  isApplyingCrmRedemption: boolean;
  isCheckingOut: boolean;
  isDeletingUnavailableProducts: boolean;
  isFixingUnavailableCheckout: boolean;
  isLoading: boolean;
  isRemovingSpecialHasChangedFlag: boolean;
  queryPromoCode: string | undefined;
  replacementCart: null | StoreAndCartProduct;
  salesTaxLoading: boolean;
  tags: {} | undefined;
  userGroupSpecialId?: Id;
  wasLoadedAt: Date | undefined;
}>;

const getDefaultCart = (): CartState['cart'] => ({
  all_discounts_total: 0.0,
  brand_discounts_service_fee_total: 0.0,
  brand_discounts_total: 0.0,
  brand_discounts_total_with_fee: 0.0,
  brand_special_has_changed: false,
  cart_discount_amount: 0.0,
  cart_discount_special_id: undefined,
  crm_redemptions: [],
  delivery_fee_amount: 0.0,
  discounted_product_total: 0.0,
  discounted_subtotal: 0.0,
  group_discounts_total: 0.0,
  group_special_id: null,
  group_special_title: null,
  headless_v2: false,
  is_open: false,
  item_total: 0.0,
  jane_pay_service_fee_amount: null,
  localized_products_content: [],
  loyalty_points_stacking_enabled: true,
  max_specials_per_cart: null,
  max_specials_per_cart_hit: false,
  order_total: 0.0,
  product_discounts_total: 0.0,
  products: [],
  removed_crm_redemptions: [],
  reservation_mode: 'pickup',
  sales_tax_amount: 0.0,
  sales_tax_rate: 0.0,
  service_fee_amount: 0.0,
  special_has_changed: false,
  status: undefined,
  store: undefined,
  store_tax_amount: 0.0,
  tax_included: false,
  tip_amount: '0.0',
  unavailable_products: [],
  uuid: '',
});

const getInitialState = (): CartState => ({
  analyzingSpecials: false,
  cart: getDefaultCart(),
  checkoutError: null,
  deliveryAddress: Storage.get('deliveryAddress') || null,
  hasCheckoutError: false,
  hasLoaded: false,
  isAddingItemToCart: false,
  isApplyingCrmRedemption: false,
  isCheckingOut: false,
  isDeletingUnavailableProducts: false,
  isFixingUnavailableCheckout: false,
  isLoading: false,
  isRemovingSpecialHasChangedFlag: false,
  queryPromoCode: undefined,
  replacementCart: null,
  salesTaxLoading: false,
  tags: undefined,
  userGroupSpecialId: undefined,
  wasLoadedAt: undefined,
});

const clear = (state: CartState) => ({
  ...state,
  cart: getDefaultCart(),
  deliveryAddress: null,
  replacementCart: null,
  userGroupSpecialId: undefined,
});

export const cartReducer: Reducer<CartState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  const { cart } = state;

  switch (action.type) {
    case OPEN_CART: {
      return { ...state, cart: { ...cart, is_open: true } };
    }

    case SET_HEADLESS_V2_CART: {
      return { ...state, cart: { ...cart, headless_v2: true } };
    }

    case CLOSE_CART: {
      return { ...state, cart: { ...cart, is_open: false } };
    }

    case ADDING_ITEM_TO_CART: {
      return { ...state, isAddingItemToCart: true };
    }

    case ADDING_ITEM_TO_CART_FAILED: {
      return { ...state, isAddingItemToCart: false };
    }

    case CART_ADD: {
      const { store, product, userAgent, cartUuid } = action.payload;
      return {
        ...state,
        cart: {
          ...cart,
          products: cart.products.concat(product),
          status: 'pending',
          store,
          user_agent: userAgent,
          uuid: cartUuid,
        },
        isAddingItemToCart: false,
      };
    }

    case GET_CART_ERROR:
    case CART_CLEAR_ERROR:
    case CART_CLEAR:
      return clear({
        ...state,
        hasLoaded: true,
        isAddingItemToCart: false,
        isLoading: false,
      });

    case CART_UPDATE: {
      const { cart } = state;
      const updatedProduct = action.payload;

      const products = cart.products.map((product) =>
        product.id === updatedProduct.product_id &&
        product.price_id === updatedProduct.price_id
          ? {
              ...product,
              count: updatedProduct.count,
            }
          : product
      );
      return {
        ...state,
        cart: {
          ...cart,
          products,
        },
      };
    }

    case PAYTENDER_CHECKOUT_BEGIN:
      return { ...state, isCheckingOut: true };

    case PAYTENDER_CHECKOUT_SUCCESS:
      return {
        ...clear(state),
        isCheckingOut: false,
      };

    case PAYTENDER_CHECKOUT_CANCELLED:
      return { ...state, isCheckingOut: false };

    case CHECKOUT:
      return { ...state, isCheckingOut: true };

    case CHECKOUT_SUCCESS:
      return { ...clear(state), hasLoaded: true, isCheckingOut: false };

    case CHECKOUT_ERROR:
      return {
        ...state,
        checkoutError:
          typeof action.payload === 'string'
            ? action.payload
            : 'Something went wrong, please try again. If the problem persists, please contact us at support@iheartjane.com',
        hasCheckoutError: true,
        isCheckingOut: false,
      };

    case DISMISS_CHECKOUT_ERROR:
      return { ...state, checkoutError: null, hasCheckoutError: false };

    case CREATE_CART:
      return { ...state, isLoading: true };

    case GET_CART:
      return { ...state, isLoading: true };

    case GET_REMOTE_CART_SUCCESS:
      return {
        ...state,
        wasLoadedAt: new Date(),
      };

    case GET_USER_CART_SUCCESS: {
      const { cart } = state;
      /*
          the cart/get_for_user endpoint doesn't know the user address
          since it can be called from any point of the app after log in
          so we won't overwrite any work done on the cart that may have
          updated its address/delivery_fee since that's the last true value
          we have to do the same for the order total, since the one from the
          get_for_user endpoint won't add the delivery fee either
        */
      return {
        ...state,
        cart: {
          ...getDefaultCart(),
          ...action.payload.cart,
          delivery_fee_amount: cart.delivery_fee_amount || 0,
          headless_v2: state.cart.headless_v2,
          is_open: state.cart.is_open,
          order_total: cart.order_total,
        },
        hasLoaded: true,
        isLoading: false,
      };
    }

    case GET_CART_SUCCESS:
      return {
        ...state,
        cart: {
          ...getDefaultCart(),
          ...action.payload.cart,
          headless_v2: state.cart.headless_v2,
          is_open: state.cart.is_open,
        },
        hasLoaded: true,
        isLoading: false,
      };

    case REFRESH_CART:
      return {
        ...state,
        cart: {
          ...getDefaultCart(),
          ...action.payload.cart,
          headless_v2: state.cart.headless_v2,
          is_open: state.cart.is_open,
        },
        hasLoaded: true,
        isLoading: false,
      };

    case CREATE_HEADLESS_CART_SUCCESS:
      return {
        ...state,
        cart: {
          ...getDefaultCart(),
          ...action.payload.cart,
        },
        hasLoaded: true,
        isLoading: false,
      };
    case WILL_REPLACE_SERVER_CART:
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
      };

    case ANALYZE_SPECIALS:
      return { ...state, analyzingSpecials: true };

    case ANALYZE_SPECIALS_SUCCESS: {
      const { cart, userGroupSpecialId } = action.payload;

      // use only amounts, not the whole cart object, until the cart optimizer stops interpreting product price_id changes as special_has_changed
      const {
        all_discounts_total,
        brand_discounts_total,
        brand_special_has_changed,
        cart_discount_amount,
        cart_discount_special_id,
        crm_redemptions,
        delivery_fee_amount,
        discounted_product_total,
        discounted_subtotal,
        group_discounts_total,
        group_special_id,
        group_special_title,
        item_total,
        order_total,
        product_discounts_total,
        products,
        promo_code,
        removed_crm_redemptions,
        sales_tax_amount,
        sales_tax_rate,
        service_fee_amount,
        store_tax_amount,
        unavailable_products,
      } = cart;

      return {
        ...state,
        analyzingSpecials: false,
        cart: {
          ...state.cart,
          all_discounts_total,
          brand_discounts_total,
          brand_special_has_changed,
          cart_discount_amount,
          cart_discount_special_id,
          crm_redemptions,
          delivery_fee_amount,
          discounted_product_total,
          discounted_subtotal,
          group_discounts_total,
          group_special_id,
          group_special_title,
          item_total,
          order_total,
          product_discounts_total,
          products,
          promo_code,
          removed_crm_redemptions,
          sales_tax_amount,
          sales_tax_rate,
          service_fee_amount,
          store_tax_amount,
          unavailable_products,
        },
        userGroupSpecialId,
      };
    }

    case APPLY_CRM_REDEMPTION: {
      return {
        ...state,
        isApplyingCrmRedemption: true,
      };
    }

    case UPDATE_CRM_REDEMPTIONS: {
      return {
        ...state,
        cart: {
          ...state.cart,
          ...action.payload.cart,
        },
        isApplyingCrmRedemption: false,
      };
    }

    case REMOVE_CRM_REDEMPTION_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        cart: {
          ...state.cart,
          crm_redemptions: state.cart.crm_redemptions?.filter(
            (redemption) => redemption.id !== id
          ),
        },
      };
    }

    case CLEAR_REMOVED_CRM_REDEMPTIONS: {
      return {
        ...state,
        cart: {
          ...state.cart,
          removed_crm_redemptions: [],
        },
      };
    }

    case CART_UPDATE_TIP: {
      return {
        ...state,
        cart: {
          ...state.cart,
          tip_amount: action.payload,
        },
      };
    }

    case CART_DELETE_ITEM:
      if (
        state.cart.products.length === 1 &&
        state.cart.products[0].id === action.payload.productId
      ) {
        return clear(state);
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          products: state.cart.products.filter(
            (product) =>
              !(
                product.id === action.payload.productId &&
                product.price_id === action.payload.priceId
              )
          ),
        },
      };

    case FIX_UNAVAILABLE_CHECKOUT:
      return { ...state, isFixingUnavailableCheckout: true };

    case UNAVAILABLE_CHECKOUT_FIXED:
      return { ...state, isFixingUnavailableCheckout: false };

    case DELETE_UNAVAILABLE_PRODUCTS:
      return { ...state, isDeletingUnavailableProducts: true };

    case DELETE_UNAVAILABLE_PRODUCTS_SUCCESS: {
      const newState = {
        ...state,
        cart: { ...state.cart, unavailable_products: [] },
        checkoutError: null,
        hasCheckoutError: false,
        isDeletingUnavailableProducts: false,
        isFixingUnavailableCheckout: false,
      };

      return state.cart.products.length === 0 ? clear(newState) : newState;
    }

    case REMOVE_SPECIAL_HAS_CHANGED_FLAG:
      return { ...state, isRemovingSpecialHasChangedFlag: true };

    case REMOVE_SPECIAL_HAS_CHANGED_FLAG_SUCCESS:
      return {
        ...state,
        cart: { ...cart, special_has_changed: false },
        isRemovingSpecialHasChangedFlag: false,
      };

    case REMOVE_BRAND_SPECIAL_HAS_CHANGED_FLAG_SUCCESS:
      return {
        ...state,
        cart: {
          ...cart,
          brand_special_has_changed: false,
        },
      };

    case PREPARE_TO_REPLACE_CART:
      return { ...state, replacementCart: action.payload };

    case CART_UPDATE_RESERVATION_MODE:
      return {
        ...state,
        cart: {
          ...state.cart,
          reservation_mode: action.payload.reservationMode,
        },
      };

    case CART_UPDATE_TAGS:
      return {
        ...state,
        tags: action.payload,
      };

    case CART_UPDATE_PROMO_CODE:
      return {
        ...state,
        queryPromoCode: action.payload,
      };

    case UPDATE_RESERVATION_MODE_SUCCESS: {
      const { cart } = action.payload;
      const {
        products,
        special_has_changed,
        crm_redemptions,
        removed_crm_redemptions,
        delivery_fee_amount,
        discounted_subtotal,
      } = cart;
      return {
        ...state,
        cart: {
          ...state.cart,
          crm_redemptions,
          delivery_fee_amount,
          discounted_subtotal,
          products,
          removed_crm_redemptions,
          special_has_changed,
        },
      };
    }

    case SET_USER_GROUP_SPECIAL_ID: {
      const { id: userGroupSpecialId } = action.payload;

      return {
        ...state,
        analyzingSpecials: false,
        userGroupSpecialId,
      };
    }

    case CART_UPDATE_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.payload.deliveryAddress,
      };
    case CART_REMOVE_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: null,
      };
  }

  return state;
};

export const cartStorageKey = (appMode: AppMode, storeId: Id | undefined) => {
  if (['embedded', 'whiteLabel', 'framelessEmbed'].includes(appMode)) {
    return `cart-${appMode}-${storeId}`;
  }

  return `cart-${appMode}`;
};

const transferLegacyCartTags = (cartKey: string) => {
  const storeAppCart = Storage.get(cartKey);
  if (storeAppCart?.tags && !isEmpty(storeAppCart.tags)) {
    Storage.set(`${cartKey}-tags`, storeAppCart.tags);
  }
};

export const persistCartToLocalStorage = (
  store: ReturnType<typeof configureCustomerStore>
) => {
  let lastAppMode: AppMode | undefined = undefined;
  let lastCart: CartState['cart'] | null = null;
  let lastDeliveryAddress: CartState['deliveryAddress'] | null = null;
  let lastTags: CartState['tags'] | undefined = undefined;
  let lastQueryPromoCode: CartState['queryPromoCode'] | undefined = undefined;

  store.subscribe(() => {
    const {
      cart: { cart, deliveryAddress, queryPromoCode, tags },
      embeddedApp: { appMode, partnerId },
    } = store.getState();

    const cartKey = cartStorageKey(appMode, partnerId);
    // this fallback can be removed after namespaced local storage cart tags have been in prod for >2 weeks
    transferLegacyCartTags(cartKey);

    const persist = (cart: CartState['cart']) => {
      if (lastCart === cart) return;

      lastCart = cart;

      const localCart = {
        products: cart.products.map((product) => ({
          count: product.count,
          id: product.id,
          price_id: product.price_id,
        })),
        reservation_mode: cart.reservation_mode,
        status: cart.status,
        store: cart.store && pick(cart.store, ['id', 'name']),
        uuid: cart.uuid,
      };

      Storage.set(cartKey, localCart);
    };

    if (lastTags !== tags) {
      lastTags = tags;
      Storage.set(`${cartKey}-tags`, tags);
    }

    if (lastQueryPromoCode !== queryPromoCode) {
      lastQueryPromoCode = queryPromoCode;
      Storage.set(`${cartKey}-promo-code`, queryPromoCode);
    }

    if (appMode !== lastAppMode) {
      const cartKey = cartStorageKey(appMode, partnerId);
      lastAppMode = appMode;
      Storage.set(`${cartKey}-promo-code`, queryPromoCode);
      Storage.set(`${cartKey}-tags`, tags);
    }

    if (lastDeliveryAddress !== deliveryAddress) {
      lastDeliveryAddress = deliveryAddress;
      Storage.set('deliveryAddress', deliveryAddress);
    }

    if (cart.status || lastCart) {
      persist(cart);
    }
  });
};
