import { useEffect, useState } from 'react';

import { useEcommApp } from '@jane/shared/providers';
import { Drawer, useMobileMediaQuery } from '@jane/shared/reefer';

import { openModal } from '../../../common/redux/application';
import { useCustomerDispatch, useCustomerSelector } from '../../../customer';
import { closeCart } from '../../../customer/redux/cart';
import { isEmbeddedModeSelector } from '../../../customer/selectors';
import CartAlerts from '../../cartAlerts';
import { CartDrawerContainer } from './cartDrawer';

export const MarketplaceCartDrawer = ({
  container = 'body',
}: {
  container?: string | HTMLElement;
}) => {
  const [showCartAlerts, setShowCartAlerts] = useState<boolean>(false);
  const isMobile = useMobileMediaQuery({});
  const dispatch = useCustomerDispatch();
  const { appMode } = useEcommApp();
  const {
    cart,
    isRemovingSpecialHasChangedFlag,
    isDeletingUnavailableProducts,
  } = useCustomerSelector(
    ({
      cart: {
        cart,
        isRemovingSpecialHasChangedFlag,
        isDeletingUnavailableProducts,
      },
    }) => ({
      appMode,
      cart,
      isRemovingSpecialHasChangedFlag,
      isDeletingUnavailableProducts,
    })
  );

  const isEmbeddedMode = useCustomerSelector(isEmbeddedModeSelector);

  const {
    is_open: isCartOpen,
    special_has_changed,
    unavailable_products,
    removed_crm_redemptions,
  } = cart;

  const cartHasChanged =
    (special_has_changed && !isRemovingSpecialHasChangedFlag) ||
    (unavailable_products &&
      unavailable_products.length &&
      !isDeletingUnavailableProducts) ||
    (removed_crm_redemptions && removed_crm_redemptions.length > 0);

  useEffect(() => {
    /*
      We don't want to trigger openModal on subdomains so we need
      to also check if we are on embeddedMode
    */
    if (isCartOpen && cartHasChanged && !isEmbeddedMode) {
      dispatch(
        openModal({
          name: 'cartAlerts',
        })
      );
    }
  }, [isCartOpen, cartHasChanged]);

  return (
    <Drawer
      container={container}
      width={isMobile ? '95vw' : '540px'}
      open={isCartOpen}
      onRequestClose={() => dispatch(closeCart())}
      background="grays-ultralight"
    >
      <CartDrawerContainer
        appMode={appMode}
        showCartAlerts={setShowCartAlerts}
      />
      {isEmbeddedMode && showCartAlerts && (
        <CartAlerts onClose={() => setShowCartAlerts(false)} isCheckoutAlert />
      )}
    </Drawer>
  );
};
