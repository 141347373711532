import type { MenuProduct, Store } from '@jane/shared/models';
import type {
  MenuProduct as zMenuProduct,
  Store as zStore,
} from '@jane/shared/types';

export const getSpecialTagLabel = (
  product: MenuProduct | zMenuProduct,
  store: Store | zStore,
  shortenLabel?: boolean
) => {
  const specialAmount = product?.special_amount;
  const complianceLabel =
    store?.store_compliance_language_settings?.['specials_badge'];

  const specialLabel =
    specialAmount && specialAmount !== 'sale' ? specialAmount : 'SALE';

  const useComplianceLanguage =
    specialAmount?.toUpperCase() === 'SALE' ||
    specialLabel === 'SALE' ||
    (shortenLabel && specialLabel.split(' ').length > 2);

  const labelToRender =
    complianceLabel && useComplianceLanguage ? complianceLabel : specialLabel;

  return shortenLabel && labelToRender.length > 7
    ? `${labelToRender.substring(0, 7)}...`
    : labelToRender;
};
