import snakeCase from 'lodash/snakeCase';
import type { NavigateFunction } from 'react-router-dom';
import type { Reducer } from 'redux';

import type { SSOChannel } from '@jane/shared-ecomm/tracking';
import {
  trackCustomerLogin,
  trackCustomerRegistration,
} from '@jane/shared-ecomm/tracking';
import { removeJwt, setJwt } from '@jane/shared/auth';
import type { DeepReadonly, LoginCredentials, User } from '@jane/shared/models';
import { trackError } from '@jane/shared/util';

import { HANDLE_INVALID_LOGIN_TOKEN } from '../../common/redux/login';
import type { AppleAuthResponse } from '../../components/login/components/appleLogin/useAppleSDK';
import { paths } from '../../lib/routes';
import { createSimpleAction, createStandardAction } from '../../redux-util';
import { CustomerSource } from '../../sources/customer';
import { UsersSource } from '../../sources/users';
import type { CustomerThunkAction } from '../redux';
import { isEmbeddedModeSelector } from '../selectors';
import { getCartForUser, removeCartUser } from './cart';
import { VERIFY_STORE_SUCCESS } from './embeddedApp';
import { UPDATE_IDENTIFICATION_SUCCESS } from './identification';
import { isNoStore } from './store';
import type { CustomerAction } from './types';
import { UPDATE_USERS } from './users';

export const CUSTOMER_LOG_OUT = 'customer/log-out';
export const logOut =
  (onSuccess?: () => void): CustomerThunkAction =>
  (dispatch) => {
    CustomerSource.logOut()
      .then(() => removeJwt())
      .then(() => {
        dispatch({ type: CUSTOMER_LOG_OUT });
        dispatch(removeCartUser());
        onSuccess && onSuccess();
      });
  };

export const CUSTOMER_LOG_IN = 'customer/log-in';
export const logIn =
  ({
    credentials,
    nextPathname,
    navigate,
    source,
  }: {
    credentials: LoginCredentials;
    navigate: NavigateFunction;
    nextPathname: string | null;
    source?: string;
  }): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: CUSTOMER_LOG_IN });

    CustomerSource.logIn(credentials).then((response) => {
      const { body, headers } = response;
      const { user, errors } = body;

      if (errors) {
        return dispatch(logInError(errors.error));
      }

      return dispatch(
        logInSuccess({ user, headers, nextPathname, navigate, source })
      );
    });
  };

export const logInWithCognitoToken =
  (token: string): CustomerThunkAction<Promise<any>> =>
  (dispatch) => {
    dispatch({ type: CUSTOMER_LOG_IN });

    return CustomerSource.logInWithCognitoToken(token).then((response) => {
      const { body, headers } = response;
      const { user, errors } = body;

      if (errors) {
        return dispatch(logInError(errors.error));
      }

      return dispatch(headlessLogInSuccess({ user, headers }));
    });
  };

export const SET_LOCAL_USER = 'customer/set-local';
export const setLocalUser =
  (user: Partial<User>): CustomerThunkAction =>
  (dispatch) => {
    dispatch({
      type: SET_LOCAL_USER,
      payload: user,
    });
  };

export const CUSTOMER_LOG_IN_SUCCESS = 'customer/log-in-success';
export const logInSuccess =
  ({
    user,
    headers,
    nextPathname,
    source,
    ssoChannel,
    navigate,
  }: {
    headers: any;
    navigate?: NavigateFunction | false;
    nextPathname: string | null;
    source?: string;
    ssoChannel?: SSOChannel;
    user: User;
  }): CustomerThunkAction =>
  (dispatch, getState) => {
    if (headers && headers['Authorization']) {
      setJwt(headers['Authorization']);
    }
    trackCustomerLogin({ ssoChannel, source });
    dispatch({
      type: CUSTOMER_LOG_IN_SUCCESS,
      payload: user,
    });

    dispatch(getCartForUser());

    const state = getState();
    const { appMode } = state.embeddedApp;
    const embeddedMode = isEmbeddedModeSelector(state);

    const path =
      nextPathname && !nextPathname.includes('logout')
        ? nextPathname
        : appMode === 'brandEmbed'
        ? paths.profile()
        : embeddedMode
        ? paths.embeddedMenu()
        : paths.root();

    if (navigate) {
      navigate(path, { replace: true });
    }
  };

export const HEADLESS_CUSTOMER_LOG_IN_SUCCESS =
  'customer/headless-log-in-success';
export const headlessLogInSuccess =
  ({ user, headers }: { headers: any; user: User }): CustomerThunkAction =>
  (dispatch) => {
    if (headers && headers['Authorization']) {
      setJwt(headers['Authorization'], true);
    }
    trackCustomerLogin({ ssoChannel: 'cognito', source: 'headless' });
    dispatch({
      type: HEADLESS_CUSTOMER_LOG_IN_SUCCESS,
      payload: user,
    });
  };

export const CUSTOMER_LOG_IN_ERROR = 'customer/log-in-error';
export const logInError = createStandardAction(CUSTOMER_LOG_IN_ERROR)<
  string | null
>();

export const CUSTOMER_REGISTER_SUCCESS = 'customer/register-success';
export const registerSuccess =
  (user: User): CustomerThunkAction =>
  (dispatch) => {
    dispatch(getCartForUser());
    dispatch({ type: CUSTOMER_REGISTER_SUCCESS, payload: user });
  };

export const CUSTOMER_REGISTER_ERROR = 'customer/register-error';
export const registerError = createStandardAction(CUSTOMER_REGISTER_ERROR)<
  string | null
>();

export const CUSTOMER_WHOAMI = 'customer/whoami';
export const whoami =
  ({ ref }: { ref?: string } = {}): CustomerThunkAction =>
  (dispatch) => {
    dispatch({ type: CUSTOMER_WHOAMI });

    CustomerSource.whoami({ ref }).then(
      (response) => {
        const { user } = response;
        return dispatch(whoamiSuccess(user));
      },
      (err) => dispatch(whoamiError(err))
    );
  };

export const CUSTOMER_WHOAMI_ERROR = 'customer/whoami-error';
export const whoamiError = createStandardAction(
  CUSTOMER_WHOAMI_ERROR
)<unknown>();

export const CUSTOMER_WHOAMI_SUCCESS = 'customer/whoami-success';
export const whoamiSuccess = createStandardAction(
  CUSTOMER_WHOAMI_SUCCESS
)<User>();

export const CUSTOMER_AUTHENTICATE_GOOGLE_CODE =
  'customer/authenticate-google-code';
export const authenticateGoogleCode =
  ({
    code,
    nextPathname = null,
    onAuthSuccess,
    navigate,
    source,
  }: {
    code: any;
    navigate: NavigateFunction | false;
    nextPathname: string | null;
    onAuthSuccess?: () => void;
    source?: string;
  }): CustomerThunkAction =>
  (dispatch, getState) => {
    dispatch({ type: CUSTOMER_AUTHENTICATE_GOOGLE_CODE });

    const state = getState();
    const { appMode, partnerId, partnerName } = state.embeddedApp;
    const { store } = state.store;
    const storeId = isNoStore(store) ? undefined : store.id;

    CustomerSource.authenticateGoogleCode(code)
      .then((response) => {
        const { body, headers } = response;
        // track new oauth identity linked to existing account:
        const { user, errors } = body;

        if (!user || errors) {
          return dispatch(logInError(errors.error));
        }

        user.authenticated = true;

        dispatch(
          logInSuccess({
            user,
            headers,
            nextPathname,
            source,
            ssoChannel: 'google',
            navigate,
          })
        );

        if (user && user.new_registration) {
          UsersSource.update({
            user: {
              app_mode_at_registration: snakeCase(appMode),
              store_id_at_registration: storeId,
            },
          }).catch((error) => {
            trackError(new Error('User update failed'), {
              error,
            });
          });

          trackCustomerRegistration({
            customer: { ...user, ssoChannel: 'google' },
            partnerChannel: appMode,
            partnerId,
            partnerName,
          });
        }

        onAuthSuccess && onAuthSuccess();
      })
      .catch((error) =>
        trackError(new Error('Google authentication failed'), {
          error,
        })
      );
  };

interface AppleAuthParams {
  first_name?: string;
  id_token: string;
  last_name?: string;
}

export const CUSTOMER_AUTHENTICATE_APPLE_CODE =
  'customer/authenticate-apple-code';
export const authenticateAppleCode =
  ({
    authMetadata,
    nextPathname = null,
    onAuthSuccess,
    navigate,
    source,
  }: {
    authMetadata: AppleAuthResponse;
    navigate: NavigateFunction | false;
    nextPathname: string | null;
    onAuthSuccess?: () => void;
    source?: string;
  }): CustomerThunkAction =>
  (dispatch, getState) => {
    const auth: AppleAuthParams = {
      id_token: authMetadata.authorization.id_token,
    };

    if (authMetadata.user) {
      auth.first_name = authMetadata.user?.name?.firstName;
      auth.last_name = authMetadata.user?.name?.lastName;
    }

    dispatch({ type: CUSTOMER_AUTHENTICATE_APPLE_CODE });

    const state = getState();
    const { appMode, partnerId, partnerName } = state.embeddedApp;
    const { store } = state.store;
    const storeId = isNoStore(store) ? undefined : store.id;

    CustomerSource.authenticateAppleCode(auth)
      .then((response) => {
        const { body, headers } = response;
        // track new oauth identity linked to existing account:
        const { user, errors } = body;

        if (!user || errors) {
          return dispatch(logInError(errors.error));
        }

        user.authenticated = true;

        dispatch(
          logInSuccess({
            user,
            headers,
            nextPathname,
            source,
            ssoChannel: 'apple',
            navigate,
          })
        );

        if (user && user.new_registration) {
          UsersSource.update({
            user: {
              app_mode_at_registration: snakeCase(appMode),
              store_id_at_registration: storeId,
            },
          }).catch((error) => {
            trackError(new Error('User update failed'), {
              error,
            });
          });

          trackCustomerRegistration({
            customer: { ...user, ssoChannel: 'apple' },
            partnerChannel: appMode,
            partnerId,
            partnerName,
          });
        }

        onAuthSuccess && onAuthSuccess();
      })
      .catch((error) =>
        trackError(new Error('Apple authentication failed'), {
          error,
        })
      );
  };

export const CUSTOMER_MFA_SUCCESS = 'customer/mfa-success';
export const mfaSuccess = createSimpleAction(CUSTOMER_MFA_SUCCESS);

export type CustomerActions =
  | { type: typeof CUSTOMER_LOG_OUT }
  | { type: typeof CUSTOMER_LOG_IN }
  | ReturnType<typeof logInError>
  | { payload: User; type: typeof CUSTOMER_REGISTER_SUCCESS }
  | ReturnType<typeof registerError>
  | { type: typeof CUSTOMER_WHOAMI }
  | ReturnType<typeof whoamiSuccess>
  | ReturnType<typeof whoamiError>
  | { type: typeof CUSTOMER_AUTHENTICATE_GOOGLE_CODE }
  | { payload: User; type: typeof CUSTOMER_LOG_IN_SUCCESS }
  | { payload: User; type: typeof HEADLESS_CUSTOMER_LOG_IN_SUCCESS }
  | { payload: Partial<User>; type: typeof SET_LOCAL_USER }
  | { type: typeof CUSTOMER_MFA_SUCCESS }
  | { type: typeof CUSTOMER_AUTHENTICATE_APPLE_CODE };

export type CustomerReducerState = DeepReadonly<{
  authenticated: boolean;
  avatar: number | null;
  birth_date: string;
  email: string | null;
  externalId: string | null;
  firstName: string | null;
  id: number | null;
  isEmbeddedMode: boolean;
  lastMfa: Date | null;
  lastName: string | null;
  loading: boolean;
  logInErrorMessage: string | null;
  name: string | null;
  nickname: string | null;
  phone: string | null;
  sessionChecked: boolean;
}>;

const getInitialState = (): CustomerReducerState => ({
  isEmbeddedMode: false,
  sessionChecked: false,
  loading: false,
  logInErrorMessage: null,
  authenticated: false,
  avatar: null,
  birth_date: '',
  email: null,
  externalId: null,
  firstName: null,
  id: null,
  lastName: null,
  name: null,
  nickname: null,
  phone: null,
  lastMfa: null,
});

const setUser = (state: CustomerReducerState, user: User) => ({
  ...state,
  authenticated: user.authenticated || false,
  avatar: user.avatar,
  birth_date: user['birth_date'] || '',
  email: user.email || '',
  externalId: user.externalId || null,
  firstName: user['first_name'] || '',
  id: user.id,
  lastName: user['last_name'],
  name: user.name,
  nickname: user.nickname,
  phone: user.phone,
});

const setUserFromPartialIdentification = (
  state: CustomerReducerState,
  user: Partial<User>
) => {
  const firstName =
    typeof user.first_name === 'string' ? user.first_name : state.firstName;
  const lastName =
    typeof user.last_name === 'string' ? user.last_name : state.lastName;
  const name = [firstName, lastName].join(' ');

  return {
    ...state,
    firstName,
    lastName,
    name,
  };
};

const reset = (state: CustomerReducerState) => ({
  ...state,
  loading: false,
  authenticated: false,
  avatar: null,
  birth_date: '',
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  name: null,
  nickname: null,
  phone: null,
});

export const customerReducer: Reducer<CustomerReducerState, CustomerAction> = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case HANDLE_INVALID_LOGIN_TOKEN:
      return state.id ? { ...state, authenticated: false } : state;
    case CUSTOMER_LOG_IN:
    case CUSTOMER_WHOAMI:
      return { ...state, loading: true };
    case CUSTOMER_LOG_IN_ERROR:
    case CUSTOMER_REGISTER_ERROR:
      return { ...state, loading: false, logInErrorMessage: action.payload };
    case SET_LOCAL_USER:
      return {
        ...state,
        phone: action.payload.phone || null,
        email: action.payload.email || null,
        firstName: action.payload.first_name || null,
        lastName: action.payload.last_name || null,
        birth_date: action.payload.birth_date || '',
        externalId: action.payload.externalId || null,
      };
    case HEADLESS_CUSTOMER_LOG_IN_SUCCESS:
    case CUSTOMER_LOG_IN_SUCCESS:
    case CUSTOMER_REGISTER_SUCCESS:
      return setUser(
        { ...state, loading: false, logInErrorMessage: null },
        action.payload
      );
    case UPDATE_IDENTIFICATION_SUCCESS:
      return setUserFromPartialIdentification(state, action.payload);
    case CUSTOMER_LOG_OUT:
    case CUSTOMER_WHOAMI_ERROR:
      return reset(state);
    case CUSTOMER_WHOAMI_SUCCESS:
      return setUser(
        { ...state, loading: false, sessionChecked: true },
        action.payload
      );
    case UPDATE_USERS:
      return setUser(state, action.payload);
    case VERIFY_STORE_SUCCESS:
      return { ...state, isEmbeddedMode: true };
    case CUSTOMER_MFA_SUCCESS:
      return { ...state, lastMfa: new Date() };
  }
  return state;
};
