import { api, generateTrackingUrls, generateUrl } from '../api';
import type { Params } from '../api';
import type { JaneDMIdentifiers } from '../types/config';
import {
  placementCartToppers,
  placementMenuInline,
  placementMenuInlineTable,
  placementRecommendedRow,
} from '../types/placements';
import { SdkError } from '../utils/sdkError';
import { typedJSONParse } from '../utils/typedJsonParse';
import type {
  CreateSmartSortProps,
  FetchSmartSortApiResponse,
  FetchSmartSortBody,
  FetchSmartSortNextPageApiResponse,
  FetchSmartSortNextPageBody,
  FetchSmartSortProduct,
  GeneratedProducts,
  SmartSortCachedProductProps,
  SmartSortClickPayload,
  SmartSortImpressPayload,
  SmartSortNextPageProps,
  SmartSortProductProps,
  SmartSortProps,
} from './smartSort.types';

/**
 * Represents a product, called menu product in the Jane ecosystem, for a
 * smart sort placement.
 */
export class SmartSortProduct {
  /**
   * The primary identifier for this product.
   */
  public objectId: string;
  /**
   * The attributes of this product.
   * The shape and data of this field will change depending on the requested
   * attributes.
   * @example
   * ```jsx
   * { product_id: '1234' }
   * ```
   */
  public attributes: Record<string, any>;
  /**
   * Boolean that indicates if a product is sponsored or not
   */
  public isSponsored: boolean;

  private impressUrl: string;
  private impressPayload: SmartSortImpressPayload;
  private clickUrl: string;
  private clickPayload: SmartSortClickPayload;
  private hasBeenImpressed: boolean;

  /**
   * Builds a new SmartSortProduct instance based on the provided properties.
   * This method should be used in conjunction with `serialize()` to recreate the instance.
   */
  constructor(props: SmartSortProductProps) {
    this.objectId = props.objectId;
    this.attributes = props.attributes;
    this.impressUrl = props.impressUrl;
    this.impressPayload = props.impressPayload;
    this.clickPayload = props.clickPayload;
    this.clickUrl = props.clickUrl;
    this.isSponsored = props.isSponsored;
    this.hasBeenImpressed = props.hasBeenImpressed ?? false;
  }

  /**
   * Records attribution data for this product. This method
   * should be called when the product is visible by the user in
   * the viewport, as a visible DOM element.
   * See {@link https://www.npmjs.com/package/react-intersection-observer react-intersection-observer} for a React component that can be used to detect when an element is in the viewport.
   * @example
   * ```jsx
   * import { InView } from "react-intersection-observer";
   *
   * const Component = () => (
   *   <InView onChange={(inView) => {
   *     if(inView) {
   *       product.impress();
   *     }
   *   }}>
   *     <ProductCard />
   *   </InView>
   * );
   * ```
   */
  public impress(): Promise<void> {
    if (this.hasBeenImpressed) {
      return Promise.resolve();
    }

    return api.post(this.impressUrl, this.impressPayload).then(() => {
      this.hasBeenImpressed = true;
    });
  }

  /**
   * Records attribution data for this product. This method
   * should be called when the product is clicked by the user.
   * @example
   * ```jsx
   * placement.products.map((product) => {
   *   return (<ProductCard
   *     product={product}
   *     onClick={() => product.click()}
   *     key={product.objectId}
   *   />);
   * });
   * ```
   */
  public click(): Promise<void> {
    return api.post(this.clickUrl, this.clickPayload);
  }

  /**
   * Converts the object in a JSON-serializable data structure that should be
   * used **unmodified** in the constructor to create a new instance of this class.
   * This method should be used in conjunction with `new SmartSortProduct()` to recreate the instance.
   *
   * This function is especially helpful in a server-side environment to
   * keep track of the same instance across client side and server side
   * rendered components.
   * @example
   * ```jsx
   * let serializedObj = obj.serialize();
   * let deserializedObj = new SmartSortProduct(serializedObj);
   * ```
   */
  public serialize(): SmartSortProductProps {
    return {
      attributes: this.attributes,
      clickPayload: this.clickPayload,
      clickUrl: this.clickUrl,
      hasBeenImpressed: this.hasBeenImpressed,
      impressPayload: this.impressPayload,
      impressUrl: this.impressUrl,
      isSponsored: this.isSponsored,
      objectId: this.objectId,
    };
  }

  /**
   * Converts the object to a JSON string, using `serialize()` behind the
   * scenes. The returned value should be used **unmodified** in the
   * `SmartSortProduct.fromJSON()` static method.
   * This method should be used in conjunction with `SmartSortProduct.fromJSON()` to recreate the instance.
   */
  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  /**
   * Converts the previously-serialized object back to an instance of this class.
   * This method should be used in conjunction with `toJSON()` to recreate the instance.
   */
  public static fromJSON(serialized: string): SmartSortProduct {
    return new SmartSortProduct(
      typedJSONParse<SmartSortProductProps>(serialized)
    );
  }
}

export class SmartSortCachedProduct {
  public objectId: string;
  public isSponsored: boolean;

  private impressUrl: string;
  private impressPayload: SmartSortImpressPayload;
  private clickUrl: string;
  private clickPayload: SmartSortClickPayload;

  constructor(props: SmartSortCachedProductProps) {
    this.objectId = props.objectId;
    this.impressUrl = props.impressUrl;
    this.impressPayload = props.impressPayload;
    this.clickPayload = props.clickPayload;
    this.clickUrl = props.clickUrl;
    this.isSponsored = props.isSponsored;
  }

  public serialize(): SmartSortCachedProductProps {
    return {
      clickPayload: this.clickPayload,
      clickUrl: this.clickUrl,
      impressPayload: this.impressPayload,
      impressUrl: this.impressUrl,
      isSponsored: this.isSponsored,
      objectId: this.objectId,
    };
  }

  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  public static fromJSON(serialized: string): SmartSortCachedProduct {
    return new SmartSortCachedProduct(
      typedJSONParse<SmartSortCachedProductProps>(serialized)
    );
  }
}

/**
 * Represents a configurable placement that can be used in
 * different type of pages.
 *
 * In a server side context, this instance can be recreated over to the
 * client by using the **unmodified** properties provided by the `serialize()`
 * method.
 */
export class SmartSort {
  /**
   * A mix of organic and sponsored products for this placement.
   * @example
   * ```jsx
   * placement.products.map((product) => {
   *   return (<ProductCard
   *     product={product}
   *     onClick={() => product.click()}
   *     key={product.objectId}
   *   />);
   * });
   * ```
   */
  public products: SmartSortProduct[];
  /**
   * A map of search facets for this placement.
   * @example
   * ```json
   * {
   *   "category": {
   *     "sativa": 20,
   *     "indica": 10,
   *   },
   *   "kind": {
   *     "flower": 31,
   *     "vape": 5,
   *   },
   * }
   * ```
   */
  public searchFacets?: Record<string, Record<string, number>>;
  /**
   * The total number of products available for this placement.
   */
  public totalProducts: number;
  /**
   * The title of the placement.
   */
  public title: string | undefined;

  private cachedProducts: SmartSortCachedProduct[];
  private nextPageProps: SmartSortNextPageProps;
  private nextPageUrl: string;

  /**
   * Builds a new SmartSort instance based on the provided properties.
   * This method should be used in conjunction with `serialize()` to recreate the instance.
   */
  constructor(props: SmartSortProps) {
    this.searchFacets = props.searchFacets;
    this.nextPageUrl = props.nextPageUrl;
    this.nextPageProps = props.nextPageProps;
    this.products = props.products.map((p) => new SmartSortProduct(p));
    this.cachedProducts = props.cachedProducts.map(
      (p) => new SmartSortCachedProduct(p)
    );
    this.totalProducts = props.totalProducts;
    this.title = props.title;
  }

  /**
   * Boolean that indicates if more products are available for this placement.
   */
  public hasNextPage(): boolean {
    return this.cachedProducts.length > 0;
  }

  /**
   * Retrieves the next page of mixed organic and sponsored products for
   * this placement.
   *
   * The `products` property will have the new products appended.
   * @example
   * ```jsx
   * function ProductsList({ placement }) {
   *   let [hasMoreProducts, setHasMoreProducts] = useState(placement.hasNextPage());
   *   let [products, setProducts] = useState(placement.products);
   *
   *   let loadMore = (async () => {
   *     await placement.nextPage();
   *     setHasMoreProducts(placement.hasNextPage());
   *     setProducts([...placement.products]);
   *   });
   *
   *   return (
   *     <div>
   *       <button onclick={() => {loadMore()}} disabled={!hasMoreProducts}>Load more</button>
   *       <div>
   *         {products.map((product) => {
   *           return (<ProductCard
   *             product={product}
   *             onClick={() => product.click()}
   *             key={product.objectId}
   *           />);
   *         }}
   *       </div>
   *     </div>
   *   );
   * }
   * ```
   */
  public async nextPage(): Promise<void> {
    const nextPageProducts = this.cachedProducts.slice(
      0,
      this.nextPageProps.pageSize
    );
    const body: FetchSmartSortNextPageBody = {
      object_ids: nextPageProducts.map((p) => p.objectId),
      search_attributes: this.nextPageProps.searchAttributes,
      store_id: this.nextPageProps.storeId,
    };

    const resp = (await api.post(
      this.nextPageUrl,
      body
    )) as FetchSmartSortNextPageApiResponse;

    const mapResult = new Map(
      resp.products.map((obj) => [obj.object_id, obj.search_attributes])
    );

    nextPageProducts.forEach((p) => {
      const attributes = mapResult.get(p.objectId);
      if (attributes !== undefined) {
        this.products.push(
          new SmartSortProduct({
            ...p.serialize(),
            attributes: attributes,
          })
        );
      }
    });

    this.cachedProducts = this.cachedProducts.slice(
      this.nextPageProps.pageSize
    );
  }

  /**
   * Converts the object in a JSON-serializable data structure that should be
   * used **unmodified** in the constructor to create a new instance of this class.
   * This method should be used in conjunction with `new SmartSort()` to recreate the instance.
   *
   * This function is especially helpful in a server-side environment to
   * keep track of the same instance across client side and server side
   * rendered components.
   * @example
   * ```jsx
   * let serializedObj = obj.serialize();
   * let deserializedObj = new SmartSort(serializedObj);
   * ```
   */
  public serialize(): SmartSortProps {
    return {
      cachedProducts: this.cachedProducts.map((p) => p.serialize()),
      nextPageProps: this.nextPageProps,
      nextPageUrl: this.nextPageUrl,
      products: this.products.map((p) => p.serialize()),
      searchFacets: this.searchFacets,
      title: this.title,
      totalProducts: this.totalProducts,
    };
  }

  /**
   * Converts the object to a JSON string, using `serialize()` behind the
   * scenes. The returned value should be used **unmodified** in the
   * `SmartSort.fromJSON()` static method.
   * This method should be used in conjunction with `SmartSort.fromJSON()` to recreate the instance.
   */
  public toJSON(): string {
    return JSON.stringify(this.serialize());
  }

  /**
   * Converts the previously-serialized object back to an instance of this class.
   * This method should be used in conjunction with `toJSON()` to recreate the instance.
   */
  public static fromJSON(serialized: string): SmartSort {
    return new SmartSort(typedJSONParse<SmartSortProps>(serialized));
  }
}

export class SmartSortClient {
  private generateProducts(
    products: FetchSmartSortProduct[] | null,
    impressUrl: string,
    clickUrl: string,
    identifier: JaneDMIdentifiers
  ): GeneratedProducts {
    const result: GeneratedProducts = {
      cachedProducts: [],
      products: [],
    };

    products?.forEach((responseProduct) => {
      const product: SmartSortCachedProductProps = {
        clickPayload: {
          adToken: responseProduct.ad_token,
          distinctId: identifier.mixpanelDistinctId ?? identifier.jdid,
          janeDeviceId: identifier.jdid,
          kevelToken: responseProduct.kevel_token,
          myHighD: responseProduct.my_high_d,
          productId: responseProduct.product_id,
        },
        clickUrl: clickUrl,
        impressPayload: {
          adToken: responseProduct.ad_token,
          distinctId: identifier.mixpanelDistinctId ?? identifier.jdid,
          janeDeviceId: identifier.jdid,
          kevelToken: responseProduct.kevel_token,
          myHighD: responseProduct.my_high_d,
          productId: responseProduct.product_id,
        },
        impressUrl: impressUrl,
        isSponsored: !!responseProduct.flight_id,
        objectId: responseProduct.object_id,
      };

      if (responseProduct.search_attributes !== undefined) {
        result.products.push({
          ...product,
          attributes: responseProduct.search_attributes,
        });
      } else {
        result.cachedProducts.push(product);
      }
    });

    return result;
  }

  private buildProductIdsFilterString(productIds: number[]): string {
    if (productIds.length === 0) {
      return '';
    }

    const uniqueIds = Array.from(new Set(productIds));
    return `${uniqueIds.map((pid) => `NOT product_id:${pid}`).join(' AND ')}`;
  }

  private buildFetchSmartSortBody(
    props: CreateSmartSortProps
  ): FetchSmartSortBody {
    if (props.placement === placementCartToppers) {
      const {
        appMode,
        storeId,
        identifier,
        placement,
        cartProductIds,
        searchAttributes,
      } = props;

      return {
        app_mode: appMode,
        distinct_id: identifier.mixpanelDistinctId,
        jane_device_id: identifier.jdid,
        placement,
        search_attributes: searchAttributes,
        search_filter: this.buildProductIdsFilterString(cartProductIds),
        store_id: storeId,
      };
    } else if (props.placement === placementRecommendedRow) {
      const {
        placement,
        appMode,
        storeId,
        identifier,
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
      } = props;

      return {
        app_mode: appMode,
        distinct_id: identifier.mixpanelDistinctId,
        jane_device_id: identifier.jdid,
        placement,
        search_attributes: searchAttributes,
        search_filter: searchFilter,
        search_optional_filters: searchOptionalFilters,
        store_id: storeId,
      };
    } else if (props.placement === placementMenuInlineTable) {
      const {
        placement,
        appMode,
        storeId,
        identifier,
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
        searchQuery,
        disableAds,
        maxProducts,
        numColumns,
        pageSize,
        searchFacets,
        searchSort,
      } = props;

      return {
        app_mode: appMode,
        disable_ads: disableAds,
        distinct_id: identifier.mixpanelDistinctId,
        jane_device_id: identifier.jdid,
        max_products: maxProducts,
        num_columns: numColumns,
        page_size: pageSize,
        placement,
        search_attributes: searchAttributes,
        search_facets: searchFacets,
        search_filter: searchFilter,
        search_optional_filters: searchOptionalFilters,
        search_query: searchQuery,
        search_sort: searchSort,
        store_id: storeId,
      };
    } else if (props.placement === placementMenuInline) {
      const {
        placement,
        appMode,
        storeId,
        identifier,
        searchAttributes,
        searchFilter,
        searchOptionalFilters,
        searchQuery,
        disableAds,
        numColumns,
        searchSort,
      } = props;

      return {
        app_mode: appMode,
        disable_ads: disableAds,
        distinct_id: identifier.mixpanelDistinctId,
        jane_device_id: identifier.jdid,
        num_columns: numColumns,
        placement,
        search_attributes: searchAttributes,
        search_filter: searchFilter,
        search_optional_filters: searchOptionalFilters,
        search_query: searchQuery,
        search_sort: searchSort,
        store_id: storeId,
      };
    } else {
      throw new SdkError(
        `Invalid Smart Sort Placement: ${(props as any).placement}`
      );
    }
  }

  public async createSmartSort(
    props: CreateSmartSortProps
  ): Promise<SmartSort> {
    const path = `/v2/smart`;
    const params: Params = {
      apiKey: props.apiKey,
      source: props.source,
      version: props.version,
    };
    const url = generateUrl(props.endpoint, path, params);
    const nextPagePath = `/v2/smartpage`;
    const nextPageUrl = generateUrl(props.endpoint, nextPagePath, params);

    const body = this.buildFetchSmartSortBody(props);

    const response = (await api.post(url, body)) as FetchSmartSortApiResponse;

    const { clickEndpoint: clickUrl, impressionEndpoint: impressUrl } =
      generateTrackingUrls(props.endpoint, params);

    const { search_facets: searchFacets, products: responseProducts } =
      response;
    const { products, cachedProducts } = this.generateProducts(
      responseProducts,
      impressUrl,
      clickUrl,
      props.identifier
    );

    return new SmartSort({
      cachedProducts: cachedProducts,
      nextPageProps: {
        pageSize: body.page_size ?? 60,
        searchAttributes: body.search_attributes,
        storeId: body.store_id,
      },
      nextPageUrl: nextPageUrl,
      products: products,
      searchFacets,
      totalProducts: response.nb_hits,
      ...(props.placement === placementRecommendedRow && {
        title: props.title,
      }),
    });
  }
}
