import { useShouldShowGold } from '@jane/shared/hooks';
import type { MenuProduct } from '@jane/shared/models';
import {
  Flex,
  Image,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import {
  generateCustomLabel,
  getJaneGoldTagLabel,
  getSpecialTagLabel,
  productPhotoMetadata,
  titleize,
} from '@jane/shared/util';

import { JaneGoldTag } from '../../janeGoldTag';
import { Category } from '../../legacyProductCard';
import { useProductCardContext } from '../../productCardProvider/productCardProvider';
import { potencyInfo } from '../../productPotency';
import { SpecialTag } from '../../specialTag';
import { FiveStarRating } from '../../starRatings';
import { useProductReviewsEnabled } from '../utils/useProductReviewsEnabled';
import { StyledCardContent } from './listViewProductCard.styles';

export const ProductInfoCardContent = () => {
  const {
    appMode,
    currentSpecial,
    janeGoldLabel,
    menuProduct,
    store,
    userLocation,
  } = useProductCardContext();
  const { special_amount, brand, category, kind, brand_subtype, name } =
    menuProduct as MenuProduct;
  const specialTagLabel =
    store &&
    special_amount &&
    getSpecialTagLabel(menuProduct as MenuProduct, store, true);
  const isVerySmallMobile = useMobileMediaQuery({ width: 'max', size: 'sm' });
  const smallerThanVerticalTablet = useMobileMediaQuery({});
  const shouldShowGold = useShouldShowGold({ product: menuProduct, store });

  const productLineage = generateCustomLabel({
    appMode,
    store,
    attribute: category,
    isLineageLabel: true,
    fallback: titleize(category),
  });

  const specialApplies = specialTagLabel && currentSpecial;

  const photoMetadata = productPhotoMetadata(menuProduct);

  const reviewsEnabled = useProductReviewsEnabled(appMode, userLocation, store);

  const janeGoldTagLabel = shouldShowGold
    ? janeGoldLabel ||
      (menuProduct &&
        getJaneGoldTagLabel({
          menuProduct: menuProduct,
        }))
    : false;

  return (
    <StyledCardContent>
      <Flex
        flexDirection={
          isVerySmallMobile
            ? 'column'
            : smallerThanVerticalTablet
            ? 'row-reverse'
            : 'row'
        }
        alignItems={isVerySmallMobile ? 'center' : 'flex-start'}
      >
        <Flex position="relative">
          <Image
            src={photoMetadata.url}
            altText={menuProduct?.name || ''}
            border
            borderRadius="rounded"
            height="96px"
            width="96px"
            loading="lazy"
            responsive
            sizes="thumbnail"
          />
          <Flex position="absolute" top={8} right={8}>
            {specialApplies && <SpecialTag label={specialTagLabel} />}
          </Flex>
        </Flex>
        <Flex
          pl={smallerThanVerticalTablet ? 0 : 16}
          flexDirection="column"
          width="100%"
        >
          <Flex flexDirection={'column'}>
            {category && (
              <Category
                title={category || ''}
                category={category}
                editingMode={false}
              >
                {productLineage}
              </Category>
            )}
            <Typography variant="header-bold" branded>
              {name}
            </Typography>

            <Flex flexWrap="wrap">
              {!!brand && <Typography color="purple">{brand}</Typography>}
              {janeGoldTagLabel && (
                <JaneGoldTag my={2} ml={4} label={janeGoldTagLabel} />
              )}
            </Flex>

            <Flex flexWrap="wrap">
              <Typography>
                {brand_subtype ? brand_subtype : titleize(kind)}
              </Typography>
              <Typography color="text-light" ml={16}>
                {menuProduct && potencyInfo(menuProduct)}
              </Typography>
            </Flex>
          </Flex>

          {reviewsEnabled && menuProduct && menuProduct.aggregate_rating ? (
            <Flex alignItems="center">
              <FiveStarRating rating={menuProduct.aggregate_rating} />

              <Typography mx={4}>{menuProduct.aggregate_rating}</Typography>
              <Typography color="text-light">
                ({menuProduct.review_count})
              </Typography>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </StyledCardContent>
  );
};

export default ProductInfoCardContent;
